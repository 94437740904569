import React, { useEffect, useState } from "react";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useContext } from "react";
import { DatePickerWithRange } from "../../../components/DateRangePicker";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/Select";
import moment from "moment/moment";

const TotalsTable = () => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const { selectedLocation } = useContext(SelectedLocationContext);

  const [date, setDate] = useState({
    from: new Date(),
    to: new Date(),
  });

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `transactions/totals?location=${selectedLocation}&pm_id=${selectedPaymentMethod}${
          date?.from ? `&from=${moment(date.from).format("YYYY-MM-DD")}` : ""
        }${date?.to ? `&to=${moment(date.to).format("YYYY-MM-DD")}` : ""}`
      );

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: res?.data?.data?.total,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, [date, selectedPaymentMethod, selectedLocation]);

  //get all payment method
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(true);
  const getAllPayment = async () => {
    try {
      const res = await http.get(`/financials/${selectedLocation}/payment-method-accounts`);
      setPaymentMethods(res?.data?.data);
    } catch (error) {
    } finally {
      setLoadingPaymentMethod(false);
    }
  };

  useEffect(() => {
    getAllPayment();
  }, [selectedLocation]);

  return (
    <div className="w-full h-full relative">
      {loadingPaymentMethod ? (
        <LoadingCircle />
      ) : (
        <>
          <div className=" mt-4 flex items-center gap-2 z-50">
            <Select onValueChange={setSelectedPaymentMethod}>
              <SelectTrigger className="w-[190px] h-[62px] text-[16px]">
                <SelectValue placeholder="Payment methods" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={""}>All</SelectItem>
                {paymentMethods?.map((p) => (
                  <SelectItem value={p?.qboId}>{p?.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            <DatePickerWithRange date={date} setDate={setDate} />
          </div>
          <div className="flex mt-4 border-[1px]  w-fit  min-w-[300px] border-[#E4E4E4] bg-white p-3 rounded-[10px] ">
            <div className="text-[30px] " style={{ fontFamily: "gilroy-bold" }}>
              <div style={{ fontFamily: "gilroy-bold" }}>Total Transactions</div>
              <div className="mt-3 font-bold ">{+Number(pageState?.data).toFixed(2)} EGP</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TotalsTable;
