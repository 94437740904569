import React, { useEffect, useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import BackButton from "../../../../assets/backButton.png";
import { useNavigate, useParams } from "react-router-dom";
import terminatedIcon from "../../../../assets/icons/terminatedIcon.png";
import timeIcon from "../../../../assets/icons/timeIcon.png";
import vasIcon from "../../../../assets/icons/vasIcon.png";
import { Button } from "../../../../components/Button/Button";
import NewPaymentModal from "./NewPaymentModal";
import TerminationModal from "./TrminationModal";
import { http } from "../../../../utils/httpCommon";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import moment from "moment";
import { floor } from "lodash";
import { useAuthUser } from "react-auth-kit";
const RentDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const params = useParams();
  const auth = useAuthUser();
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await http.get(`rent-contracts/${params.id}`);
      setData(res?.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const navigate = useNavigate();
  const [openNewPaymentModal, setOpenNewPaymentModal] = useState(false);

  const handleNewPaymentModal = () => {
    setOpenNewPaymentModal(true);
  };

  const [openTerminationModal, setOpenTerminationModal] = useState(false);

  const handleTerminationModal = () => {
    setOpenTerminationModal(true);
  };

  let sum = data?.transactions?.reduce((accumulator, object) => {
    return accumulator + object.amount;
  }, 0);

  return (
    <>
      <NewPaymentModal
        data={data}
        fetchData={fetchData}
        opened={openNewPaymentModal}
        setOpened={setOpenNewPaymentModal}
      />
      <TerminationModal
        data={data}
        fetchData={fetchData}
        opened={openTerminationModal}
        setOpened={setOpenTerminationModal}
      />
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <Layout>
          <div className="bg-white min-h-screen p-[5%] mx-[5%]">
            <button
              onClick={() => navigate(-1)}
              className="flex justify-start w-full  gap-2  items-center"
            >
              <img
                className="w-[35px] h-[35px] object-contain"
                src={BackButton}
                alt="back-button"
              />
              Back
            </button>

            <div className="p-4 flex gap-[40px]  items-center">
              {data?.company?.logo && (
                <div>
                  <img src={data?.company?.logo} alt="Icon" className=" w-[125px] object-cover " />
                </div>
              )}

              <div>
                {auth().role === "Experience Officer" ||
                auth().role === "Branch Manager" ||
                auth().role === "Area Manager" ||
                auth().role === "Land Lord" ? null : (
                  <button onClick={() => handleTerminationModal()} className="flex items-center">
                    <img src={terminatedIcon} alt="Icon" className="w-[34px] h-[34px] " />
                    <span className="mx-[15px] text-[#686868] font-semibold">
                      {" "}
                      Terminate Contract
                    </span>
                  </button>
                )}

                <div className="mt-[15px] flex flex-col ">
                  <span className="text-[#797979] text-[20px] font-bold">Contract Number</span>
                  <span
                    className="text-[#011516] text-[18px] "
                    style={{ fontFamily: "gilroy-bold" }}
                  >
                    {data?.rentNumber}
                  </span>
                  <span className="text-[#011516] text-[24px] font-bold ">
                    {data?.company?.name}
                  </span>
                </div>

                <div className="mt-[15px] flex flex-col ">
                  <div className="flex items-start text-[18px]  gap-2 text-black">
                    <img src={timeIcon} alt="Time Icon" className=" w-[26px] h-[26px] mt-1 " />
                    <div>
                      <span>Rented for {data?.duration} months</span>
                      <br />
                      <span
                        className="text-[#011516] text-[20px] "
                        style={{ fontFamily: "gilroy-bold" }}
                      >
                        {moment(data?.startDate).format(" Do MMM  YYYY")}-{" "}
                        {moment(data?.endDate).format(" Do MMM  YYYY")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-[15px] flex flex-col ">
                  <div
                    className="text-[#011516] text-[18px] "
                    style={{ fontFamily: "gilroy-bold" }}
                  >
                    Status
                  </div>

                  <div className=" text-[#34B2C0]  text-[16px] w-fit bg-[#3bb2c0] bg-opacity-20 font-semibold p-2 flex  justify-center items-center rounded-[25px]   ">
                    {data?.status}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="px-10 p-2">
              <div
                className=" text-[#011516] text-[20px] font-bold "
                // style={{ fontFamily: "gilroy-bold" }}
              >
                Rent Details
              </div>

              <div className="flex items-start mt-[10px] ">
                <img src={vasIcon} alt="vas Icon " className="w-[26px]" />
                <div className=" mx-4 ">
                  <div className="flex items-center">
                    <span className=" text-[#011516] text-[20px] font-bold ">Added VAS</span>
                    <button className="mx-4 text-[#32B3BE] text-[20px] ">+ Add VAS</button>
                  </div>
                  {data?.vasList?.length > 0 && (
                    <>
                      <div className="text-[#797979] text-[20px] ">VAS Items</div>
                      <div className=" bg-[#F6F8F7] min-h-[96px]  gap-[30px] rounded-[10px] flex items-center px-[20px]">
                        <div className="flex flex-col text-[16px]">
                          <span className="text-[#797979]  ">VAS Items</span>
                          <span className="text-black">Security Camera</span>
                        </div>

                        <div className="flex flex-col text-[16px]">
                          <span className="text-[#797979]  ">Unit</span>
                          <span className="text-black">1</span>
                        </div>

                        <div className="flex flex-col text-[16px]">
                          <span className="text-[#797979]  ">QTY</span>
                          <span className="text-black">10</span>
                        </div>

                        <div className="flex flex-col text-[16px]">
                          <span className="text-[#797979]  ">Price</span>
                          <span className="text-black">200 EGP</span>
                        </div>

                        <div className="flex flex-col text-[16px]">
                          <span className="text-[#797979]  ">Total</span>
                          <span className="text-black">300 EGP</span>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="text-[#011516] text-[20px] font-bold  mt-[20px] ">Notes</div>
                  {data?.notes && <div className="text-[18px] text-[#797979] ">{data?.notes}</div>}

                  {/* To Do add this info from the server  */}
                  <div className="border-[1px] flex flex-col rounded-[10px]  w-fit mt-[15px] p-[15px] ">
                    <span className="font-bold">{data?.facility?.name}</span>
                    <span>{data?.facility?.location?.name}</span>

                    <div className="mt-[20px] flex  gap-[30px]">
                      <div className="flex flex-col">
                        <span className=" text-[#797979] ">Rent Price</span>
                        <span className="font-bold">
                          {data?.facility?.rentPricePerMonth} EGP Per month
                        </span>
                      </div>

                      <div className="flex flex-col">
                        <span className=" text-[#797979] ">Security Deposit</span>
                        <span className="font-bold">{data?.securityDeposit} EGP</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="mt-[15px]">
                <div
                  className=" text-[#011516] text-[18px] font-bold  mb-4"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  Total
                </div>
                <div className="bg-[#F6F8F7] w-full rounded-[10px]  flex flex-col p-[16px] gap-2 ">
                  <div className="flex justify-between">
                    <span className="font-bold text-[16px]">Rent Price</span>
                    <span className="text-[#797979] text-[16px]">
                      {data?.rentTotal}
                      EGP
                    </span>
                  </div>

                  {data?.discount && Number(data?.discount) > 0 ? (
                    <div className="flex justify-between">
                      <span className="font-bold text-[16px]">Discount</span>
                      <span className="text-[#797979] text-[16px]">-{data?.discount} EGP</span>
                    </div>
                  ) : null}

                  <div className="flex justify-between text-[16px]">
                    <span className="font-bold">WHT</span>
                    <span className="text-[#797979] ">
                      {Number(data?.whtAmount).toFixed(2)}
                      EGP
                    </span>
                  </div>

                  <div className="flex justify-between text-[16px]">
                    <span className="font-bold">VAT</span>
                    <span className="text-[#797979] ">
                      {Number(data?.taxAmount).toFixed(2)}
                      EGP
                    </span>
                  </div>

                  {/* {data?.securityDeposit && Number(data?.securityDeposit) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">Security Deposit Amount</span>
                      <span className="text-[#797979] ">
                        {Number(data?.securityDeposit).toFixed(2)}
                        EGP
                      </span>
                    </div>
                  ) : null} */}

                  {data?.vasTotal && Number(data?.vasTotal) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">VAS Total</span>
                      <span className="text-[#797979] ">
                        {Number(data?.vasTotal).toFixed(2)}
                        EGP
                      </span>
                    </div>
                  ) : null}

                  {data?.insurance && !isNaN(data?.insurance) && Number(data?.insurance) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">Insurance </span>
                      <span className="text-[#797979] ">
                        {Number(data?.insurance).toFixed(2)}
                        EGP
                      </span>
                    </div>
                  ) : null}

                  <div className="flex flex-col gap-4 mt-2">
                    <span className="font-bold text-[18px]">Total</span>
                    <span
                      className="text-[40px] text-[#011516]"
                      style={{ fontFamily: "gilroy-bold" }}
                    >
                      {Number(data?.total).toFixed(2)}
                      EGP
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* TO Do  */}

            <div className="px-10 p-3">
              <div
                className=" text-[#011516] text-[20px] font-bold  mb-2"
                // style={{ fontFamily: "gilroy-bold" }}
              >
                List of installments
              </div>
              <table className=" w-full">
                <thead className="text-left w-full text-[18px]">
                  <tr>
                    <th className="w-fit px-2 py-5">Amount</th>
                    <th className="w-fit px-2 py-5">Time Stamp</th>
                    <th className="w-fit px-2 py-5">Description</th>
                    <th className="w-fit px-2 py-5">Payment Method</th>
                  </tr>
                </thead>
                <tbody className="text-left text-[16px] bg-[#F6F8F7]  rounded-[10px]  w-full">
                  {data?.transactions?.map((t) => (
                    <tr className="" key={t._id}>
                      <td className="w-fit px-2 py-2">{t?.amount} EGP</td>
                      <td className="w-fit px-2 py-2"> {moment(t?.date).format("LLLL")}</td>
                      <td className="w-fit px-2 py-2">{t?.description ? t?.description : ""} </td>
                      <td className="w-fit px-2 py-2">
                        {t?.paymentMethodName ? t?.paymentMethodName : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className=" mt-3 bg-[#F6F8F7] w-full rounded-[10px]  flex  items-center justify-between p-[20px] gap-2 ">
                <div className="flex flex-col">
                  <span className="font-bold text-[18px]">Amount left</span>
                  <span
                    className="text-[#011516] text-[40px] "
                    style={{ fontFamily: "gilroy-bold" }}
                  >
                    {data?.total <= sum
                      ? "Completed"
                      : +Number(data?.total - sum).toFixed(2) + " EGP"}
                  </span>
                </div>
                <div>
                  {data?.total <= sum
                    ? null
                    : auth().role !== "Land Lord" && (
                        <Button onClick={() => handleNewPaymentModal()} secondary={true}>
                          {" "}
                          <span className="font-bold">Pay Amount</span>{" "}
                        </Button>
                      )}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default RentDetails;
