import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Button } from "../../../components/Button/Button";
import CheckedInCard from "./CheckedInCard";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { http } from "../../../utils/httpCommon";
import moment from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CheckInModal from "./CheckinModal";
import RightArrow from "../../../assets/icons/RightButton.svg";
import LeftArrow from "../../../assets/icons/LeftButton.svg";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useAuthUser } from "react-auth-kit";

let options = {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  day: "numeric",
  month: "long",
  year: "numeric",
};

const CheckedInTable = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);
  const auth = useAuthUser();

  const [selectedDate, setSelectedDate] = useState(moment());
  const [searchQuery, setSearchQuery] = React.useState("");
  const [status, setStatus] = useState("checked-in");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const handleChangeStartDateValue = (newValue) => {
    setSelectedDate(moment(new Date(newValue)));
  };
  const handlePrevDay = () => {
    setSelectedDate(selectedDate.clone().subtract(1, "day"));
  };

  const handleNextDay = () => {
    setSelectedDate(selectedDate.clone().add(1, "day"));
  };
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const fetchData = async () => {
    setIsLoading(true);
    if (status === "checked-in") {
      try {
        const res = await http.get(`checks/current?location=${selectedLocation}`);
        setData(
          res?.data?.data?.map((r) => ({
            id: r?._id,
            name: r?.user?.name,
            date: new Date(r?.checkInTime).toLocaleString("en-Us", options),
            startDate: moment(r?.checkInTime).format("LT"),
            endDate: "",
            ...r,
          }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const res = await http.get(
          `checks?location=${selectedLocation}&from=${moment(selectedDate).format(
            "YYYY-MM-DD"
          )}&to=${moment(selectedDate).add(1, "days").format("YYYY-MM-DD")}&page=1&limit=10000`
        );

        setData(
          res?.data?.data
            ?.filter((r) => r.checkOutTime)
            ?.map((r) => ({
              id: r?._id,
              name: r?.user?.name,
              date: new Date(r?.checkInTime)?.toLocaleString("en-Us", options),
              startDate: moment(r?.checkInTime)?.format("LT"),
              endDate: "",
              ...r,
            }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [selectedDate, status, selectedLocation]);

  const [openCheckInModal, setOpenCheckInModal] = useState(false);

  const handleCheckin = () => {
    setOpenCheckInModal(true);
  };

  return (
    <>
      <CheckInModal
        fetchData={fetchData}
        data={data}
        opened={openCheckInModal}
        setOpened={setOpenCheckInModal}
      />

      <div>
        {/* <div className="w-[90%]  flex gap-[30px] items-center ">
          <InputBase
            className="border-[1px] border-[#DEDEDE] rounded-[10px] h-[45px]  min-w-[650px] "
            value={searchQuery}
            onChange={handleChange}
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            startAdornment={<SearchIcon className="mx-[20px]" />}
          />
        </div> */}

        <div className="mb-5 mt-4 flex justify-between flex-wrap">
          <div className="relative flex-grow flex justify-start items-center">
            <div className="flex justify-center  min-w-[300px]  ">
              <button
                className="text-[32px] w-[34px] flex justify-center items-center h-[34px] hover:bg-gray-200 rounded-full transition duration-75"
                onClick={handlePrevDay}
              >
                <img src={LeftArrow} alt="Back" className="w-[12px]" />
              </button>
              <button
                className="text-[32px] w-[34px] flex justify-center items-center h-[34px] hover:bg-gray-200 rounded-full transition duration-75"
                onClick={handleNextDay}
              >
                <img src={RightArrow} alt="next" className="w-[12px]" />
              </button>
              <div className="relative ">
                <div className=" text-[20px] text-[#011516]  relative flex w-fit">
                  <div
                    className=" text-[20px] text-[#011516]  relative"
                    style={{ fontFamily: "gilroy-bold" }}
                  >
                    {selectedDate.format("dddd ")}
                    {selectedDate.format("MMMM DD, YYYY")}
                  </div>
                </div>
              </div>

              <div className="relative">
                <div className="absolute -top-[15px] ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label=""
                      placeholder=""
                      value={selectedDate}
                      onChange={handleChangeStartDateValue}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "none",
                            },
                            "& .MuiOutlinedInput-input": {
                              visibility: "hidden !important",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                border: "none !important",
                              },
                              "&:hover fieldset": {
                                border: "none !important",
                              },
                              "&.Mui-focused fieldset": {
                                border: "none !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              display: "none",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-[30px] min-w-[340px] font-bold ">
            <button
              onClick={() => setStatus("checked-in")}
              className={`p-[12px] rounded-[23px] text-[16px] ${
                status === "checked-in"
                  ? "text-[#F6F8F7] bg-[#34B2C0]  "
                  : " text-[#797979] bg-transparent border-[1px] border-[#797979] "
              }  `}
            >
              Current checked in
            </button>
            <button
              onClick={() => setStatus("checked-out")}
              className={`p-[12px] rounded-[23px] text-[18px] ${
                status === "checked-out"
                  ? "text-[#F6F8F7] bg-[#34B2C0]  "
                  : " text-[#797979] bg-transparent border-[1px] border-[#797979] "
              }  `}
            >
              Checked out
            </button>
            {auth().role !== "Land Lord" && (
              <Button onClick={handleCheckin} secondary={true} classes={"h-[58px] text-[18px]"}>
                Check in
              </Button>
            )}
          </div>
        </div>

        {isLoading ? (
          <LoadingCircle />
        ) : (
          <div className="  grid grid-cols-3 2xl:grid-cols-3    gap-3">
            {data?.map((res, index) => (
              <CheckedInCard fetchData={fetchData} key={index} response={res} status={status} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CheckedInTable;
