import React, { useCallback, useContext, useEffect, useState } from "react";
// import DataTable from "../../../components/DataTable/DataTable";
import deleteIcon from "../../../assets/icons/whiteTrash.png";
import phoneICon from "../../../assets/icons/call.png";
import { Link } from "react-router-dom";
import ReconsiliationRequest from "./ReconsiliationModal";
import VoidRequest from "./VoidRequest";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import { useAuthUser } from "react-auth-kit";

const TransactionsTable = () => {
  const { selectedLocation } = useContext(SelectedLocationContext);

  const auth = useAuthUser();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    debounced(event.target.value, 600);
    setSearchQuery(event.target.value);
  };

  ///onFilterChange
  const onFilterChange = useCallback(
    async (filterModel) => {
      try {
        let searchVal = filterModel?.items[0]?.value;

        if (searchVal?.length > 0) {
          setPageState((old) => ({
            ...old,
            isLoading: true,
          }));

          const res = await http.get(
            `/transactions?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&search=${searchQuery}`,

            JSON.stringify({
              page: pageState.page,
              limit: pageState.pageSize,
              name: searchVal,
            })
          );

          const rowsData = res.data?.data?.map((d) => ({
            id: d._id,
            ...d,
          }));

          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: rowsData,
            //get from another api
            total: res?.data?.metadata?.[0]?.count,
          }));
        } else {
          debounced();
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    [pageState.page, pageState.pageSize, selectedLocation]
  );

  const columns = [
    {
      field: "client",
      headerName: "Client",
      width: 180,
      filterable: false,
      // editable: true,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params.row?.source?._id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">{params.row?.source?.name}</div>

            {params.row?.source?.phone && (
              <div className="flex items-center">
                <img src={phoneICon} className="w-[13.5px]" alt={params.row?.source?.name} />
                {params.row?.source?.phone}
              </div>
            )}
          </Link>
        );
      },
    },
    {
      field: "location",
      headerName: "Locations",
      width: 150,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px] ">
            {params?.row?.location?.name}
          </div>
        );
      },
    },
    {
      field: "services",
      headerName: "Services",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.description.split("paid")[0]}
          </div>
        );
      },
      // editable: true,
    },

    {
      field: "Payment Method",
      headerName: "Payment Method",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.paymentMethodName}
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">{params?.row?.amount}</div>
        );
      },
      // editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-fit text-[16px]">
            <div className=" text-[#00AD3B] bg-[#00ad3b1a]  font-semibold p-3 flex  justify-center items-center rounded-[25px]  ">
              Confirmed
            </div>
          </div>
        );
      },
      // editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {auth().role !== "Land Lord" && (
              <div className="text-[#797979] flex items-center gap-2 font-extrabold text-[16px]">
                <div className="flex gap-[15px] bg-[#797979] w-fit h-fit rounded-[10px]">
                  <button
                    className=" cursor-pointer hover:bg-gray-500 p-2 rounded-[10px] "
                    onClick={() => {
                      handleVoidRequest(params.row);
                    }}
                  >
                    <img className="w-[20px] " src={deleteIcon} alt="Delete" />
                  </button>
                </div>
                Void
              </div>
            )}
          </>
        );
      },
      // editable: true,
    },
  ];

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `/transactions?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&search=${searchQuery}`,

        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          name: searchValues,
        })
      );

      const rowsData = res.data?.data?.map((d) => ({
        id: d._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        //get from another api
        total: res?.data?.metadata?.[0]?.count,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery]);

  //handle add new
  // const [openAddModal, setOpenAddModal] = useState(false);
  // const handleActionButton = () => {
  //   setOpenAddModal(true);
  // };

  //handle delete
  const [openedVoidRequest, setOpenedVoidRequest] = useState(false);
  const [voidRequestItem, setVoidRequestItem] = useState({});
  const handleVoidRequest = async (item) => {
    setVoidRequestItem(item);
    setOpenedVoidRequest(true);
  };
  return (
    <div>
      <VoidRequest
        voidRequestItem={voidRequestItem}
        opened={openedVoidRequest}
        setOpened={setOpenedVoidRequest}
        fetchData={fetchData}
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["id", "name", "phone", "email", "location", "role"]}
        title={"Transactions"}
        onFilterChange={onFilterChange}
        // actionButton={"Reconsiliation request"}
        // handleActionButton={handleActionButton}
        removeActionButton={true}
      />
    </div>
  );
};

export default TransactionsTable;
