import React, { useEffect, useState } from "react";
import moment from "moment";
import RightArrow from "../../../assets/icons/RightButton.svg";
import LeftArrow from "../../../assets/icons/LeftButton.svg";
import { useNavigate } from "react-router-dom";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import BookingsDetails from "./BookingDetails/BookingDetails";
import { Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LeftBlackArrow from "../../../assets/icons/LeftBlackArrow.svg";
import RightBlackArrow from "../../../assets/icons/RightBlackArrow.svg";

const DayView = ({
  facilities,
  appointmentsData,
  fetchAppointmentsData,
  fetchAppointmentsDataAfterDelete,
  paymentMethods,
  open,
  selectedTimeSlotData,
  setSelectedTimeSlotData,
  close,
}) => {
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(moment());

  const handleChangeStartDateValue = (newValue) => {
    setSelectedDate(moment(new Date(newValue)));
  };

  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const appointments = appointmentsData;

  const getResources = () => {
    return facilities?.data?.map((facility) => ({
      id: facility?._id,
      name: facility?.name,
      ...facility,
    }));
  };

  const handlePrevDay = () => {
    setSelectedDate(selectedDate.clone().subtract(1, "day"));
  };

  const handleNextDay = () => {
    setSelectedDate(selectedDate.clone().add(1, "day"));
  };

  const handleCellClick = (time, resource) => {
    setSelectedTimeSlot(`${time}-${resource}`);
  };

  const handleDoubleClickCell = (time, resource) => {
    open();
    const relativeTime = moment(
      moment(selectedDate).format("YYYY-MM-DD") + "T" + moment(time, "h:mm A").format("HH:mm:ss")
    );

    setSelectedTimeSlotData({
      resource: resource,
      time: moment(relativeTime),
      date: moment(selectedDate),
    });
  };

  const [opened, { open: BookingOpen, close: BookingClose }] = useDisclosure(false);
  const resources = getResources();

  const renderTimeSlots = () => {
    const startHour = moment("7:00 AM", "h:mm A");
    const endHour = moment("11:00 PM", "h:mm A");
    const timeSlots = [];

    while (startHour.isSameOrBefore(endHour)) {
      timeSlots.push(startHour.format("h:mm A"));
      startHour.add(1, "hour");
    }

    return timeSlots.map((timeSlot, index) => (
      <tr key={index}>
        <td className="border-[1px] border-[#E4E4E4]  min-w-[120px] text-center text-[#797979]  bg-[#F8F9FA] text-[16px] ">
          {timeSlot}
        </td>

        {visibleResources.map((resource, index) => (
          <td
            className={`
            ${selectedTimeSlot === `${timeSlot}-${resource?._id}` ? ` rounded-[20px]    ` : ``} 
            border-[0.5px] border-[#E4E4E4]  h-[60px] z-10  hover:rounded-[20px] hover:bg-[#34B2C0] transition-all duration-75 `}
            key={`${timeSlot}-${resource._id}-${index}`}
            onClick={() => handleCellClick(timeSlot, resource?._id)}
            onDoubleClick={() => handleDoubleClickCell(timeSlot, resource)}
          >
            <div className="flex relative flex-wrap   z-10  w-full">
              {renderAppointment(timeSlot, resource?._id)}
            </div>
          </td>
        ))}
      </tr>
    ));
  };

  const [loadingState, setLoadingState] = useState(false);
  const [responseData, setResponseData] = useState({});

  const handleOpenAppointment = async (e, id) => {
    e.stopPropagation();
    setLoadingState(true);
    try {
      const res = await http.get(`/bookings/${id}`);
      setResponseData(res?.data);
      setLoadingState(false);
      BookingOpen();
    } catch (error) {
      setLoadingState(false);
    }
  };

  const getOpenAppointmentData = async (id) => {
    setLoadingState(true);
    try {
      const res = await http.get(`/bookings/${id}`);
      setResponseData(res?.data);
      setLoadingState(false);
      BookingOpen();
    } catch (error) {
      setLoadingState(false);
    }
  };

  // *****
  const resourcesPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const handleNextResources = () => {
    const totalPages = Math.ceil(facilities?.data?.length / resourcesPerPage);
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevResources = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startResourceIndex = currentPage * resourcesPerPage;
  const endResourceIndex = startResourceIndex + resourcesPerPage;
  const visibleResources = facilities?.data?.slice(startResourceIndex, endResourceIndex) || [];

  // Render Appointement

  const renderAppointment = (time, resource) => {
    // Convert 'time' (e.g., "10:00 AM") to a moment object for the full datetime
    const startTimeSlot = moment(
      `${moment(selectedDate).format("YYYY-MM-DD")} ${time}`,
      "YYYY-MM-DD h:mm A"
    );
    const endTimeSlot = moment(startTimeSlot).add(1, "hour");

    const filteredAppointments = appointments?.filter(
      (appointment) =>
        appointment?.roomId === resource &&
        moment(appointment?.date).isSame(selectedDate, "day") && // more robust date comparison
        moment(appointment?.startDate).isBetween(startTimeSlot, endTimeSlot, undefined, "[)") // includes start time, excludes end time
    );

    return filteredAppointments?.map((appointment) => {
      const startTime = moment(appointment?.startTime * 1000);
      const endTime = moment(appointment?.endTime * 1000);
      const duration = moment.duration(endTime.diff(startTime));
      const appointmentSlots = duration.asHours();

      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            handleOpenAppointment(e, appointment?.bookingId);
          }}
          key={appointment?.id}
          className={`bg-white absolute -top-[35px]   shadow-xl  rounded-[10px] w-[99%] z-30 flex-wrap flex flex-col  justify-start items-start gap-2 py-[10px] px-1 border-[1px] border-[#32B3BE]`}
          style={{ height: `${appointmentSlots * 70}px  ` }}
        >
          <div className=" relative flex h-full w-full justify-between  pl-1 overflow-auto gap-1">
            <div className="flex flex-col h-full justify-between">
              <div>
                <div className="text-[14px] font-bold text-[#797979]">
                  {appointment?.facility?.name}
                </div>

                <div className="text-[14px] text-[#011516] font-bold">
                  {moment(appointment?.date).format("dddd")} ,
                  {moment(appointment?.date).format("LL")}
                </div>

                <div className="text-[12px] text-[#011516] ">
                  {moment(appointment?.startTime * 1000).format("LT")} -{" "}
                  {moment(appointment?.endTime * 1000).format("LT")}
                </div>
                {/* {children[1].props.data.title} */}
              </div>
              <div className="z-50 relative">
                <button
                  onClick={() => navigate(`/community/client/${appointment?.user?._id}`)}
                  className="z-50 text-[12px] font-bold text-[#797979] hover:text-[#34B2C0]"
                >
                  {appointment?.user?.name}
                </button>
                <div className="text-[14px] font-bold text-[#34B2C0] italic">
                  {appointment?.allDay && "Booked multiple days"}
                </div>
              </div>
            </div>
            <div className="">
              <button
                onClick={(e) => {
                  handleOpenAppointment(e, appointment?.bookingId);

                  // restProps.onDoubleClick();
                  // handelEdit(params?.row?.id);
                }}
                className="  z-50   group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                </div>
              </button>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="relative">
      <div className="relative w-full flex justify-center items-center">
        <div className="flex   items-center -top-8 absolute justify-center   min-w-[400px]  left-[35%] right-[50%] mb-[20px] mt-[0px]">
          <button
            className="text-[32px] w-[44px] flex justify-center items-center h-[34px] hover:bg-gray-200 rounded-full transition duration-75"
            onClick={handlePrevDay}
          >
            <img src={LeftArrow} alt="Back" className="w-[12px]" />
          </button>
          <button
            className="text-[32px] w-[44px] flex justify-center items-center h-[34px] hover:bg-gray-200 rounded-full transition duration-75"
            onClick={handleNextDay}
          >
            <img src={RightArrow} alt="next" className="w-[12px]" />
          </button>

          <div className="relative">
            <div className=" text-[20px] text-[#011516]  relative flex w-fit">
              <div
                className=" text-[20px] text-[#011516]  relative"
                style={{ fontFamily: "gilroy-bold" }}
              >
                {selectedDate.format("dddd ")}
                {selectedDate.format("MMMM DD, YYYY")}
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -bottom-[28px] -right-12">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label=""
                  placeholder=""
                  value={selectedDate}
                  onChange={handleChangeStartDateValue}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        width: "100%",
                        color: "#000000cc",
                        "& .MuiFormControl-root ": {
                          borderColor: "none",
                        },
                        "& .MuiOutlinedInput-input": {
                          visibility: "hidden !important",
                        },
                        "& .MuiInputBase-root": {
                          "& fieldset": {
                            border: "none !important",
                          },
                          "&:hover fieldset": {
                            border: "none !important",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none !important",
                          },
                        },
                        "& .MuiFormLabel-root ": {
                          display: "none",
                        },
                      }}
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        {/* New Date */}
      </div>
      {/* 
      <table className="w-full mt-2 overflow-hidden">
        <thead>
          <tr>
            <th className="flex gap-2 items-center justify-center mt-2">
              <button onClick={handlePrevResources}>prev</button>
              <button onClick={handleNextResources}>next</button>
            </th>
            {visibleResources.map((resource, index) => (
              <th
                key={resource.id}
                className="text-left min-w-[130px] w-fit h-[60px] font-semibold bg-[white] shadow-lg"
              >
                
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderTimeSlots()}</tbody>
      </table> */}

      <table className="w-full mt-2 overflow-hidden">
        <thead>
          <tr>
            <th className="flex gap-2 items-center  justify-center mt-1">
              {currentPage === 0 ? (
                <div className="min-w-[40px] p-1"></div>
              ) : (
                <button
                  className={`  ${
                    currentPage === 0 ? "" : "hover:bg-[#878787] hover:ring-2 hover:ring-white"
                  } min-w-[40px] rounded-full min-h-[40px] flex justify-center items-center  transition-all   `}
                  onClick={handlePrevResources}
                  disabled={currentPage === 0}
                >
                  <img src={LeftBlackArrow} alt="Prev" className="w-[14px]" />
                </button>
              )}

              {currentPage === Math.ceil(facilities?.data?.length / resourcesPerPage) - 1 ? (
                <div className="min-w-[40px] p-1"></div>
              ) : (
                <button
                  className={`    ${
                    currentPage === Math.ceil(facilities?.data?.length / resourcesPerPage) - 1
                      ? ""
                      : "hover:bg-[#878787]  hover:ring-2 hover:ring-white"
                  } min-w-[40px] rounded-full min-h-[40px] flex justify-center items-center transition-all `}
                  onClick={handleNextResources}
                  disabled={
                    currentPage === Math.ceil(facilities?.data?.length / resourcesPerPage) - 1
                  }
                >
                  <img src={RightBlackArrow} alt="Next" className="w-[14px]" />
                </button>
              )}
            </th>
            {visibleResources.map((resource, index) => (
              <th
                key={resource.id}
                className={`text-left  ${
                  visibleResources?.length > 1 ? "min-w-[130px] w-fit" : "w-full min-w-full"
                } h-[60px] font-semibold bg-[white] shadow-lg`}
              >
                <div className="flex items-center  text-[16px] gap-3 w-full ">
                  {resource?.mainImage && (
                    <LazyLoadImage
                      height={70}
                      width={70}
                      src={resource?.mainImage}
                      alt={resource.name}
                      className={` object-cover w-[70px] h-[50px] xl:w-[70px] xl:h-[60px] ${
                        index === 0 ? " rounded-t-[10px] " : "rounded-tr-[10px]"
                      } `}
                    />
                  )}
                  {resource.name}
                </div>
              </th>
            ))}
            {Array(resourcesPerPage - visibleResources.length)
              .fill(null)
              .map((_, index) => (
                <th
                  key={index}
                  className={`${
                    visibleResources?.length > 1 ? "min-w-[130px] w-fit" : "w-full min-w-full"
                  } h-[60px]`}
                ></th>
              ))}
          </tr>
        </thead>
        <tbody>{renderTimeSlots()}</tbody>
      </table>

      {/* 
      <table className="w-full mt-2  overflow-hidden ">
        <thead>
          <tr>
            <th>{ Time}</th>
            {resources.map((resource, index) => (
              <th
                className=" text-left min-w-[130px]  w-fit  h-[60px]  font-semibold bg-[white] shadow-lg	"
                key={resource.id}
              >
                <div className="flex items-center  text-[16px] gap-3 w-full ">
                  {resource?.mainImage && (
                    <img
                      height={70}
                      width={70}
                      loading="lazy"
                      src={resource?.mainImage}
                      alt={resource.name}
                      className={` object-cover w-[70px] h-[50px] xl:w-[70px] xl:h-[60px] ${
                        index === 0 ? " rounded-t-[10px] " : "rounded-tr-[10px]"
                      } `}
                    />
                  )}
                  {resource.name}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderTimeSlots()}</tbody>
      </table> */}
      <Drawer
        opened={opened}
        onClose={BookingClose}
        position="right"
        size={"lg"}
        overlayProps={{ opacity: 0.6, blur: 5 }}
      >
        {loadingState ? (
          <LoadingCircle />
        ) : (
          <BookingsDetails
            getOpenAppointmentData={getOpenAppointmentData}
            responseData={responseData}
            open={BookingOpen}
            close={BookingClose}
            facilities={facilities}
            paymentMethods={paymentMethods}
            fetchAppointmentsData={fetchAppointmentsDataAfterDelete}
          />
        )}
      </Drawer>
    </div>
  );
};

export default DayView;
