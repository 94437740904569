import React, { useState, useEffect, useCallback } from "react";
import { http } from "../../../utils/httpCommon";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import NewResource from "./NewResource";
import EditResource from "./EditResource";
import whiteTrash from "../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import { Link } from "react-router-dom";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import { useAuthUser } from "react-auth-kit";

const ResourcesTable = () => {
  const [searchQuery, setSearchQuery] = useState("");

  const auth = useAuthUser();
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;

    if (searchVal?.length > 0) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.post(
        `resource/all`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          name: searchVal,
        })
      );

      let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
      const rows = filteredData?.map((d) => ({
        id: d?._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows,
        total: res?.data?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  //fetching pagenated Data
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));

    const res = await http.post(
      `resource/all`,
      JSON.stringify({
        page: pageState.page,
        limit: pageState.pageSize,
        name: searchValues,
      })
    );

    let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
    const rows = filteredData?.map((d) => ({
      id: d?._id,
      ...d,
    }));

    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: rows,
      //get from another api
      total: res?.data?.data?.count,
    }));
  };

  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    {
      field: "Images",
      filterable: false,
      sortable: false,
      headerName: "",
      width: 250,
      renderCell: (params) => {
        return (
          <Link to={`/inventory/facility/${params.row.id}`}>
            <div className="flex gap-[10px] items-center  py-2">
              {params.row.icon ? (
                <img
                  className="w-[70px] object-cover h-[70px] rounded-[50px]  "
                  src={params.row?.icon}
                  alt={params.row?.name}
                />
              ) : (
                <div className="h-[70px]"></div>
              )}
            </div>
          </Link>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 300,

      renderCell: (params) => {
        return (
          <div className="flex items-center  font-semibold  text-[16px]  py-2 my-1">
            {params?.row?.name}
          </div>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: 300,
      filterable: false,

      renderCell: (params) => {
        return (
          <div className="flex items-center  font-semibold  text-[16px]  py-2 my-1">
            {params?.row?.description}
          </div>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      filterable: false,

      width: 250,

      renderCell: (params) => {
        return (
          <>
            {auth().role !== "Land Lord" && (
              <div className="flex flex-col gap-[10px]">
                <button
                  onClick={() => {
                    handelEdit(params.row.id);
                  }}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                  </div>
                  Edit
                </button>
                {auth().role === "Area Manager" ? null : (
                  <button
                    onClick={() => {
                      handleDelete(params.row);
                    }}
                    className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={whiteTrash} alt="edit" />
                    </div>
                    Delete
                  </button>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  // handle edit
  const [editId, setEditId] = useState("");
  const [openEditModal, setOpedEditModal] = useState();
  const handelEdit = async (id) => {
    try {
      const res = await http.get(`/resource/${id}`);
      setEditId(res.data.data);
      setOpedEditModal(true);
    } catch {}
  };

  //handle delete
  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleSubmitDelete = async () => {
    setDeleteLoading(true);

    try {
      await http.delete(`/resource/${deletedItem?.id}`);
      setDeleteLoading(false);
      setOpenedDelete(false);
      toast.success("Resource has been deleted successfully");
      fetchData();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  //handle add new amenity
  const [openAddModal, setOpenAddModal] = useState(false);
  const handleActionButton = () => {
    setOpenAddModal(true);
  };

  return (
    <>
      <NewResource opened={openAddModal} setOpened={setOpenAddModal} fetchData={fetchData} />
      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        loading={deleteLoading}
        deletedItem={deletedItem}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="“Please Not that all the facilities assigned tag with this resource type will be switched to None, please ensure reassigning new resource types to any affected facilities”"
      />

      <EditResource
        opened={openEditModal}
        setOpened={setOpedEditModal}
        id={editId}
        fetchData={fetchData}
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        fetchData={fetchData}
        title={"Resources"}
        actionButton={"Add Resource"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
        removeActionButton={auth().role === "Experience Officer"}
      />
    </>
  );
};

export default ResourcesTable;
