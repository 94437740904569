import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import Trash from "../../../assets/icons/whiteTrash.png";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { http } from "../../../utils/httpCommon";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import DeletePackage from "./DeletePackage";

const Package = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [openDeletePackageModal, setOpenDeletePackageModal] = useState(false);
  const navigator = useNavigate();
  const params = useParams();

  const fetchPackage = async () => {
    setLoading(true);
    try {
      const res = await http.get(`/package-tier/${params?.id}`);
      setResponse(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPackage();
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "Package Name",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.name}
          </div>
        );
      },
    },
    {
      field: "passes",
      headerName: "Passes",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.passes}
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.price}
          </div>
        );
      },
    },
    {
      field: "discount",
      headerName: "Discount",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.discount}%
          </div>
        );
      },
    },
    {
      field: "priceAfterDiscount",
      headerName: "Price After Discount",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.priceAfterDiscount}
          </div>
        );
      },
    },
    {
      field: "expireIn",
      headerName: "Expire In",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex h-[66px]  px-2 gap-[10px] items-center py-2">
            {params?.row?.expireIn} days
          </div>
        );
      },
    },
  ];
  return (
    <Layout>
      <DeletePackage
        opened={openDeletePackageModal}
        setOpened={setOpenDeletePackageModal}
        name={response?.packageTier?.name}
        id={response?.packageTier?._id}
      />
      {loading ? (
        <LoadingCircle />
      ) : (
        <div>
          <div
            className="text-[30px] mb-2 flex items-center justify-between pr-20"
            style={{ fontFamily: "gilroy-bold" }}
          >
            {response?.packageTier?.name}
            <div className="flex items-center gap-6">
              <button
                onClick={() => navigator(`/operations/edit-package/${response?.packageTier?._id}`)}
                className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                </div>
                Edit Package
              </button>
              <button
                onClick={() => setOpenDeletePackageModal(true)}
                className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={Trash} alt="Archive" />
                </div>
                Delete
              </button>
            </div>
          </div>

          <div>
            <DataGrid
              getRowHeight={() => "auto"}
              sx={{
                boxShadow: 0,
                border: 0,
                width: "100%",
                borderColor: "white",
                paddingTop: "10px",
                backgroundColor: "#f8f9fa",
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {},
                "& .MuiDataGrid-footerContainer ": {
                  backgroundColor: "white",
                  paddingBottom: "10px",
                  borderRadius: " 0 0 10px 0",
                },
                "& .MuiButtonBase-root": {
                  padding: 0,
                  margin: 0,
                },
                borderTop: "none",
                "& .MuiDataGrid-root": {
                  marginTop: "0 !important",
                },
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "& .MuiButton-text": {
                  color: "rgba(0, 0, 0, 0.54) !important",
                },
                " & .MuiDataGrid-root": {
                  backgroundColor: "white !important",
                },
                "& .MuiDataGrid-main": {
                  background: "white",
                  marginTop: "20px",
                  width: "100%",
                  borderRadius: "10px",
                },
                "& .MuiDataGrid-toolbarContainer": {
                  display: " flex ",
                  flexDirection: "row",
                  position: "relative",
                  justifyContent: "flex-end",
                  backgroundColor: "#f8f9fa",
                  padding: "5px 0 ",
                },
                "& .MuiButton-root": {
                  padding: "8px",
                  backgroundColor: "#f8f9fa !important",
                  border: "1px solid #DEDEDE",
                  margin: "0 5px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60px",
                  width: "70px",
                  fontSize: "11px",
                  marginBottom: "0px",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "1.5rem",
                },
                "& .MuiDataGrid-columnHeaders": {
                  fontSize: "18px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                },
                "& .MuiDataGrid-cellContent": {
                  fontSize: "16px",
                },
                "& .MuiIconButton-root": {
                  height: "28px",
                  width: "32px",
                },
              }}
              autoHeight
              rows={
                response?.packageTier?.packages.map((p) => {
                  return { ...p, id: p?._id };
                }) || []
              }
              disableSelectionOnClick
              responsive
              density="comfortable"
              columns={columns}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Package;
