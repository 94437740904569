import React, { useCallback, useEffect, useState } from "react";
import ProfileImg from "../../../assets/profile.png";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import Table from "../../../components/DataGrid/Table";
import { debounce } from "lodash";
import toast from "react-hot-toast";

const ClientsTable = () => {
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    try {
      if (searchVal?.length > 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));

        const res = await http.post(
          `/client/all`,
          JSON.stringify({
            page: pageState.page,
            limit: pageState.pageSize,
            textField: searchVal,
          })
        );

        let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
        const rows = filteredData?.map((d) => ({
          id: d?._id,
          ...d,
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rows,
          //get from another api
          total: res?.data?.data?.count,
        }));
      } else {
        debounced();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
    let searchVal = filterModel?.items[0]?.value;
  }, []);

  //fetching pagenated Data
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));
    try {
      const res = await http.post(
        `/client/all`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          textField: searchValues,
        })
      );

      let filteredData = res?.data?.data?.data?.filter((item) => !item?.isDeleted);
      const rows = filteredData?.map((d) => ({
        id: d?._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows,
        total: res?.data?.data?.count,
      }));
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    {
      field: "Images",
      headerName: "",
      width: 120,
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params.row.id}`}
            className="flex items-center h-[75px]  py-2 my-1"
          >
            {params?.row?.profilePicture ? (
              <img
                className="w-[70px] h-[70px]  rounded-full object-cover "
                src={params?.row?.profilePicture}
                alt={params.row.profilePicture}
              />
            ) : (
              <>
                <img
                  className="w-[70px] h-[70px]  rounded-full object-cover "
                  src={ProfileImg}
                  alt={params.row.profilePicture}
                />
              </>
            )}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 180,

      renderCell: (params) => {
        return (
          <Link
            to={`/community/client/${params.row.id}`}
            className="flex items-center  font-bold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.name}
          </Link>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 180,
    },

    {
      field: "email",
      headerName: "email",
      width: 250,
    },
    {
      field: "company",
      headerName: "Company",
      width: 180,
      renderCell: (params) => {
        return (
          <Link
            to={`/community/company/${params.row?.company?._id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 hover:text-[#34B2C0]"
          >
            {params?.row?.company?.name}
          </Link>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-[140px]">
            <div
              className={` ${
                // params?.row?.status !== "open"
                // ?
                ""
                // : "text-[#34B2C0]  bg-[#3bb2c0]"
              } 
              text-[#797979] bg-[#a09f9f] bg-opacity-20  font-semibold p-2 text-[16px] flex  justify-center items-center rounded-[25px]   `}
            >
              Not on the app
            </div>
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    navigator("/community/clients/new-client");
  };
  return (
    <>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        fetchData={fetchData}
        title={"Amenities"}
        actionButton={"Add Client"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default ClientsTable;
