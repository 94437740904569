import React from "react";
import { Modal } from "@mantine/core";

const ModalComponent = ({ opened, setOpened, children, size, onClose }) => {
  return (
    <>
      <Modal
        transitionProps={{ transition: 'fade', duration: 200, timingFunction: 'linear' }}
        radius="lg"
        // overflow="inside"
        centered
        size={size}
        opened={opened}
        onClose={onClose}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalComponent;
