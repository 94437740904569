import { createContext, useContext, useEffect, useState } from "react";
// import ShoppingCart from "../components/ShoppingCart";
// import ShoppingCart from "../components/ShoppingCart";

const VasCartContext = createContext({});

const initialCartItems = localStorage.getItem("vas-cart")
    ? JSON.parse(localStorage.getItem("vas-cart"))
    : [];

const VasCartProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [cartItems, setCartItems] = useState(initialCartItems);

    useEffect(() => {
        localStorage.setItem("vas-cart", JSON.stringify(cartItems));
    }, [cartItems]);

    const openCart = () => {
        setIsOpen(true);
    };

    const closeCart = () => {
        setIsOpen(false);
    };

    const cartQuantity = cartItems.reduce(
        (quantity, item) => item.quantity + quantity,
        0
    );
    const getItemQuantity = (data) => {
        return cartItems.find((item) => item._id === data?._id)?.quantity || 0;
    };
    const increaseCartQuantity = (data) => {
        setCartItems((currItems) => {
            if (currItems.find((item) => item._id === data?._id) == null) {
                return [...currItems, { ...data, quantity: 1 }];
            } else {
                return currItems.map((item) => {
                    if (item._id === data?._id) {
                        return { ...item, quantity: item.quantity + 1 };
                    } else {
                        return item;
                    }
                });
            }
        });
    };
    const decreaseCartQuantity = (data) => {
        setCartItems((currItems) => {
            if (currItems.find((item) => item._id === data?._id)?.quantity === 1) {
                return currItems.filter((item) => item._id !== data?._id);
            } else {
                return currItems.map((item) => {
                    if (item._id === data?._id) {
                        return { ...item, quantity: item.quantity - 1 };
                    } else {
                        return item;
                    }
                });
            }
        });
    };
    const removeFromCart = (id) => {
        setCartItems((currItems) => currItems.filter((item) => item._id !== id));
    };
    const removeAllFromCart = () => {
        setCartItems([])
        localStorage.removeItem("vas-cart")
    }
    return (
        <VasCartContext.Provider
            value={{
                cartItems,
                getItemQuantity,
                increaseCartQuantity,
                decreaseCartQuantity,
                removeFromCart,
                openCart,
                closeCart,
                removeAllFromCart,
                cartQuantity,
            }}
        >
            {children}
            {/* <ShoppingCart isOpen={isOpen} closeCart={closeCart} /> */}
        </VasCartContext.Provider>
    );
};

export default VasCartProvider;
export const useVasCart = () => {
    return useContext(VasCartContext);
};