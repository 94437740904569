import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/Button/Button";
import { http } from "../../../../utils/httpCommon";
import { TextInput } from "../../../../components/TextInput/TextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Controller } from "react-hook-form";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import ModalComponent from "../../../../components/Modal/Modal2";
import NewRentContractModalStep2 from "./NewRentContractStep2";
import Carousel from "react-grid-carousel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AsyncSelect from "react-select/async";
import moment from "moment";
import { toast } from "react-hot-toast";
import getMonthsDifferenceRounded from "../../../../utils/getMonthDifference";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { isEmpty, isNumber } from "lodash";

let calcPriceDebounceTimer;
const debounce = (func, delay) => {
  return function (...args) {
    if (calcPriceDebounceTimer) {
      clearTimeout(calcPriceDebounceTimer);
    }
    calcPriceDebounceTimer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

const NewRentContractModal = ({
  opened,
  setOpened,
  fetchData,
  rentContractModalStep,
  setRentContractModalStep,
}) => {
  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const [discountTypeValue, setDiscountTypeValue] = useState("percentage");
  const [pricing, setPricing] = useState({
    total: null,
    subTotal: null,
    vat: null,
    wht: null,
    rentPrice: null,
    discount: null,
    securityDepositAmount: null,
    vasTotal: null,
  });

  const schema = yup
    .object({
      company: yup.object().typeError("Company  is required").required("Company  is required"),
      location: yup.string().required("Location is required"),
      facilities: yup
        .array()
        .of(yup.string().required("Each facility must have an ID"))
        .min(1, "At least one facility must be selected")
        .required("Facilities are required"),
      start: yup.string().required("Start Date is required"),
      end: yup.string().required("End Date is required"),
      exemptVat: yup.bool().default(false),
      wht: yup.number().default(0),
      isInsuranceActive: yup.bool().default(false),
    })
    .required();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const location = watch("location");
  const company = watch("company");

  //check iuf

  //clicked facility
  const [checkedFacilities, setCheckedFacilities] = useState([]);
  const handleCheckedFacilities = (facility) => {
    const isAlreadyChecked = checkedFacilities.includes(facility._id);
    const updatedFacilities = isAlreadyChecked
      ? checkedFacilities.filter((f) => f !== facility._id)
      : [...checkedFacilities, facility._id];

    setCheckedFacilities(updatedFacilities);
    setCustomValue("facilities", updatedFacilities);
  };

  const {
    response: locations,
    fetchData: fetchAllLocations,
    loading: loadingLocations,
  } = useFetchData("/location/me");

  useEffect(() => {
    fetchAllLocations();
  }, []);

  const [startDate, setStartDate] = React.useState("");

  const handleChangeStartDate = (newValue) => {
    setCustomValue("start", newValue);
    setStartDate(newValue);
  };

  const [endDate, setEndDate] = React.useState("");

  const handleChangeEndDate = (newValue) => {
    setCustomValue("end", newValue);
    setEndDate(newValue);
  };

  ///close and submit
  const [responseData, setResponseData] = useState({});

  const [isInsuranceActive, setIsInsuranceActive] = useState(false);
  const [insuranceValue, setInsuranceValue] = useState("");

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsInsuranceActive(isChecked);

    // If checkbox is unchecked, clear insurance value
    if (!isChecked) {
      setInsuranceValue("");
    }
  };

  const handleInsuranceValueChange = (e) => {
    const inputValue = e.target.value;

    const insuranceSchema = yup.boolean().nullable();
    //     .number()
    //     .typeError("Insurance value must be a number")
    //     .transform((value, originalValue) => {
    //       return originalValue.trim() === "" ? 0 : value;
    //     })
    //     .max(999999.99, "Insurance value must be less than 1,000,000")
    // : yup.string().nullable();

    insuranceSchema
      .validate(inputValue)
      .then((validValue) => {
        // If the value is valid, update the state with the valid value
        setInsuranceValue(validValue);
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong while validating insurance value");
      });
  };

  const [isDiscountActive, setIsDiscountActive] = useState(false);
  const [discountValue, setDiscountValue] = useState("");

  const handleDiscountCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsDiscountActive(isChecked);

    // If checkbox is unchecked, clear discount value
    if (!isChecked) {
      setDiscountValue("");
    }
  };

  const handleDiscountValueChange = (e) => {
    const inputValue = e.target.value;

    const discountSchema = isDiscountActive
      ? yup
          .mixed()
          .transform((value, originalValue) => {
            return originalValue.trim() === "" ? 0 : value;
          })
          .typeError("Discount value should be a number")
          .test("is-number", "Discount value is required and should be a valid number", (value) => {
            if (value === undefined || value === null || value === "") {
              return true;
            }
            return !isNaN(value);
          })

          // .test("is-positive", "Discount value should be positive", (value) => {
          //   return value >= 0;
          // })
          .test(
            "is-less-than-or-equal",
            "Discount value should be less than or equal to 100%",
            (value) => {
              if (discountTypeValue === "percentage") {
                return value <= 100;
              } else {
                return true;
              }
            }
          )
          .test(
            "has-max-two-decimals",
            "Discount value must have at most two decimal places",
            (value) => {
              if (value === undefined || value === null || value === "") {
                return true;
              }
              const decimalCount = value.toString().split(".")[1]?.length || 0;
              return decimalCount <= 2;
            }
          )
      : yup.string().nullable();

    discountSchema
      .validate(inputValue)
      .then((validValue) => {
        // If the value is valid, update the state with the valid value
        setDiscountValue(validValue);
      })
      .catch((error) => {
        toast.error(error.message || "Something went wrong while validating discount value");
      });
  };

  const onSubmit = async (passedData) => {
    try {
      const res = await http.post(
        "/rent-contracts",
        JSON.stringify({
          companyId: passedData.company.value,
          facilities: passedData.facilities,
          startDate: new Date(passedData.start).toISOString(),
          endDate: new Date(passedData.end).toISOString(),
          notes: passedData.notes,
          exemptVat: passedData.exemptVat,
          discountType: discountTypeValue,
          wht: passedData.wht,
          vasList: [],
          insurance: isInsuranceActive,
          discountValue: isDiscountActive ? discountValue : 0,
        })
      );
      setResponseData(res.data?.data);
      toast.success("Rent contract created successfully");
      fetchData();
      setRentContractModalStep(2);
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message || "Something went wrong");
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  const calcPrice = async () => {
    if (
      !checkedFacilities ||
      !startDate ||
      !endDate ||
      checkedFacilities.length === 0 ||
      startDate > endDate
    ) {
      setPricing({
        total: 0,
        subTotal: 0,
        vat: 0,
        wht: 0,
        rentPrice: 0,
        discount: 0,
        securityDepositAmount: 0,
        vasTotal: 0,
      });
      return false;
    }

    try {
      const result = await http.post(
        "/rent-contracts/calc",
        JSON.stringify({
          facilities: checkedFacilities,
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
          exemptVat: watch("exemptVat"),
          wht: watch("wht"),
          insurance: isInsuranceActive,
          discountType: discountTypeValue,
          discountValue: isDiscountActive ? (discountValue === null ? 0 : discountValue) : 0,
        })
      );

      //   `/rent-contracts/calc?facility=${checkedFacilities}&startDate=${new Date(
      //     startDate
      //   ).toISOString()}&endDate=${new Date(endDate).toISOString()}&exemptVat=${watch(
      //     "exemptVat"
      //   )}&wht=${watch("wht")}&insurance=${
      //     isInsuranceActive ? insuranceValue : 0
      //   }&discountType=${discountTypeValue}&discountValue=${isDiscountActive ? discountValue : 0}`
      // );
      setPricing(result.data);
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };

  const calcPriceDebounced = debounce(calcPrice, 1000);

  useEffect(() => {
    calcPriceDebounced();
  }, [
    checkedFacilities,
    startDate,
    endDate,
    watch("wht"),
    watch("exemptVat"),
    insuranceValue,
    discountValue,
    discountTypeValue,
    isInsuranceActive,
  ]);

  const loadOptions = async (inputText, callback) => {
    let res = null;
    if (inputText.length > 0) {
      var data = JSON.stringify({
        textField: inputText,
        page: 1,
        limit: 10000,
      });
      let response = await http.post("/company/all/", data);
      res = response?.data?.data?.data;

      callback(
        res.map((i) => ({
          label: (
            <div className="flex items-center gap-3" key={i._id}>
              {i?.logo && (
                <img
                  src={i?.logo}
                  alt={i.name}
                  className="w-[40px] h-[40px] rounded-full object-cover"
                />
              )}
              {i.name}
            </div>
          ),
          value: i._id,
        }))
      );
    }
  };

  const [facilities, setFacilities] = useState([]);
  const fetchFacilities = async () => {
    try {
      const res = await http.post(
        `/facility/all`,
        JSON.stringify({
          page: 1,
          limit: 1000,
          location: location,
        })
      );
      setFacilities(res.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchFacilities();
    setCheckedFacilities([]);
  }, [location]);

  const navigator = useNavigate();
  const CustomNoOptionsMessage = ({ inputValue, onResetClick }) => (
    <div className="p-2">
      No results found{" "}
      <button
        className=" text-[18px]  text-[#34B2C0]  hover:opacity-95"
        style={{ fontFamily: "gilroy-bold" }}
        onClick={() => navigator(`/community/companies/new-company`)}
      >
        Add new Company
      </button>
    </div>
  );

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose} size={"55rem"}>
      {loadingLocations ? (
        <div className="min-h-[500px]">
          <LoadingCircle />
        </div>
      ) : rentContractModalStep === 1 ? (
        <div className="flex flex-col  px-4 min-h-500">
          <div className="text-[30px] mb-2 " style={{ fontFamily: "gilroy-bold" }}>
            New Rent Contract
          </div>

          <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
            <div className="text-[18px] my-[5px] " style={{ fontFamily: "gilroy-bold" }}>
              Basic info
            </div>

            <div className="mt-2">
              <div>
                {/* <InputLabel> Company</InputLabel> */}
                <div className="mb-2 text-[18px]">Company</div>
                <div>
                  <AsyncSelect
                    // {...field}
                    isClearable
                    components={{
                      NoOptionsMessage: ({ inputValue }) => (
                        <CustomNoOptionsMessage inputValue={inputValue} onResetClick={() => {}} />
                      ),
                    }}
                    defaultOptions
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: "60px",
                        borderRadius: "8px",
                        borderColor: "black",
                        "&:hover": {},
                      }),
                      option: (styles) => ({
                        ...styles,
                        zIndex: "999 !important",
                        backgroundColor: "white !important",
                      }),
                    }}
                    value={company}
                    onChange={(value) => {
                      // setSelectedUser(value);
                      setCustomValue("company", value);
                    }}
                    placeholder={"Search for Company"}
                    loadOptions={loadOptions}
                  />
                </div>
                <p className="text-red-500">{errors.company?.message}</p>
              </div>
            </div>

            <div className="text-[18px] my-[20px] " style={{ fontFamily: "gilroy-bold" }}>
              Facility info
            </div>

            <div className="mt-2">
              <div>
                <FormControl fullWidth sx={{ border: "none !important" }}>
                  <InputLabel> Location</InputLabel>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <Select
                        sx={{
                          color: "black",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFEFEF",
                          },
                        }}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        variant="outlined"
                        labelId="location-label"
                        id="location"
                        // value={value}
                        label="location"
                        inputRef={ref}
                        defaultValue=""
                      >
                        <MenuItem value="">
                          {" "}
                          <em>None</em>{" "}
                        </MenuItem>
                        {locations
                          ?.filter((l) => !l.isDeleted)
                          ?.map((l) => (
                            <MenuItem key={l._id} value={l._id}>
                              {l.name}
                            </MenuItem>
                          ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <p className="text-red-500">{errors.location?.message}</p>
              </div>
            </div>
            {location && (
              <>
                <div className="text-[18px] my-[10px] " style={{ fontFamily: "gilroy-bold" }}>
                  Choose facility
                </div>

                <div className=" mt-4  gap-[2px] relative w-full h-fit py-2 bg-white  ">
                  {facilities?.data?.length > 0 ? (
                    <Carousel cols={4} rows={1} gap={0} loop style={{ padding: "80px" }}>
                      {facilities?.data?.map((item, index) => {
                        return (
                          <Carousel.Item key={item._id}>
                            <div
                              className={`ml-5  p-1 cursor-pointer hover:bg-[#32b3be1a]  transition-all 
                              ${
                                checkedFacilities.includes(item._id) ? "bg-[#32b3be1a]" : ""
                              }                                 
                              font-semibold
                              border-[1px] flex justify-start items-start py-1  ] 
                              rounded-[10px] w-[170px] h-full `}
                              onClick={() => {
                                handleCheckedFacilities(item);
                              }}
                            >
                              {item.mainImage ? (
                                <LazyLoadImage
                                  height={70}
                                  width={70}
                                  className="w-[68px] h-[75px] object-cover rounded-[10px] "
                                  src={item.mainImage}
                                  alt={item.name}
                                />
                              ) : (
                                <div className="w-[68px] h-[75px]"></div>
                              )}

                              <div className="flex flex-col justify-between   h-full  mx-1">
                                <span className="font-bold text-[16px] ">{item.name}</span>
                                <span className="text-[#34B2C0] underline   text-[15px]">
                                  Quick View
                                </span>
                              </div>
                            </div>
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  ) : null}
                  <p className="text-red-500 mt-2">
                    {errors.facilities?.message}
                    {facilities?.data?.length < 1 && " - " + "This location has no facilities"}{" "}
                  </p>
                </div>
              </>
            )}

            {/* start and end date */}

            <div className="text-[18px] my-[10px] " style={{ fontFamily: "gilroy-bold" }}>
              Rent Details
            </div>

            <div className="flex  w-full gap-[30px]">
              <div className="w-1/2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="MM/DD/YYYY"
                    value={startDate}
                    onChange={handleChangeStartDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "100%",
                          color: "#000000cc",
                          "& .MuiFormControl-root ": {
                            borderColor: "#EFEFEF",
                          },
                          "& .MuiInputBase-root": {
                            "& fieldset": {
                              borderColor: "#EFEFEF !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#207beb !important",
                            },
                          },
                          "& .MuiFormLabel-root ": {
                            color: "#000000cc !important",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>

                <p className="text-red-500">{errors.start?.message}</p>
              </div>
              <div className="w-1/2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="MM/DD/YYYY"
                    value={endDate}
                    onChange={handleChangeEndDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          width: "100%",
                          color: "#000000cc",
                          "& .MuiFormControl-root ": {
                            borderColor: "#EFEFEF",
                          },
                          "& .MuiInputBase-root": {
                            "& fieldset": {
                              borderColor: "#EFEFEF !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "black !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#207beb !important",
                            },
                          },
                          "& .MuiFormLabel-root ": {
                            color: "#000000cc !important",
                          },
                        }}
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                <p className="text-red-500">{errors.end?.message}</p>
              </div>
            </div>

            {startDate && endDate && (
              <div className="flex text-[18px] gap-[20px]">
                <span className="text-[#797979]">Rent Duration: </span>
                <span>
                  {(() => {
                    const start = moment(new Date(startDate));
                    const end = moment(new Date(endDate));
                    const fullMonths = end.diff(start, "months");
                    const startOfNextMonth = start.clone().add(fullMonths, "months");

                    // If the end date is on or after the start of the next month, count it as an additional month
                    const duration = end.isSameOrAfter(startOfNextMonth)
                      ? fullMonths + 1
                      : fullMonths + 1;

                    return duration;
                  })()}
                </span>
              </div>
            )}

            <div className="flex gap-6 mt-[15px]">
              <div className="flex gap-[5px] items-center">
                <input type="checkbox" name="exemptVat" id="exemptVat" {...register("exemptVat")} />
                <label htmlFor="exemptVat">Exempt from VAT</label>
                <div className="invalid-feedback">{errors.exemptVat?.message}</div>
              </div>

              <div className="flex gap-[5px] items-center">
                <FormControl sx={{ border: "none !important", minWidth: "200px" }}>
                  <InputLabel>WHT</InputLabel>
                  <Controller
                    control={control}
                    name="wht"
                    render={({ field: { onChange, onBlur, value, name, ref } }) => (
                      <Select
                        sx={{
                          color: "black",
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#EFEFEF",
                          },
                        }}
                        onBlur={onBlur} // notify when input is touched
                        onChange={onChange} // send value to hook form
                        variant="outlined"
                        labelId="wht-label"
                        id="wht"
                        // value={value}
                        label="wht"
                        inputRef={ref}
                        defaultValue=""
                      >
                        <MenuItem value={0}>
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={0.01}>1%</MenuItem>
                        <MenuItem value={0.03}>3%</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </div>

            <div className="flex gap-6 mt-[15px]">
              <div className="flex gap-[5px] items-center">
                <input
                  type="checkbox"
                  name="isDiscountActive"
                  id="isDiscountActive"
                  checked={isDiscountActive}
                  onChange={handleDiscountCheckboxChange}
                />
                <label htmlFor="isDiscountActive">Activate Discount</label>
              </div>

              <div className="flex gap-[5px] items-center">
                <TextInput
                  label={`Discount (${discountTypeValue === "percentage" ? "%" : "EGP"})`}
                  value={discountValue}
                  onChange={handleDiscountValueChange}
                  disabled={!isDiscountActive}
                />
                <FormControl fullWidth>
                  <InputLabel id="discount-type">Discount Type</InputLabel>
                  <Select
                    sx={{
                      color: "black",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#EFEFEF",
                      },
                    }}
                    onChange={(e, { props }) => {
                      setDiscountTypeValue(props.value);
                    }}
                    disabled={!isDiscountActive}
                    variant="outlined"
                    labelId="discount label"
                    id="discount-type"
                    value={discountTypeValue}
                    label="discount-type"
                  >
                    <MenuItem value={"percentage"}>Percentage</MenuItem>
                    <MenuItem value={"amount"}>Amount</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="flex gap-6 mt-[15px]">
              <div className="flex gap-[5px] items-center">
                <input
                  type="checkbox"
                  name="isInsuranceActive"
                  id="isInsuranceActive"
                  checked={isInsuranceActive}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="isInsuranceActive">Activate Insurance</label>
              </div>

              {/* <div className="flex gap-[5px] items-center">
                <TextInput
                  label="Insurance"
                  value={insuranceValue}
                  onChange={handleInsuranceValueChange}
                  disabled={!isInsuranceActive}
                />
              </div> */}
            </div>
            <div className="mt-[15px] ">
              <TextInput label={"Notes"} {...register("notes")} />
              <p className="text-red-500">{errors.amount?.message}</p>
            </div>

            {checkedFacilities && startDate && endDate && (
              <div className="mt-[15px]">
                <div
                  className=" text-[#011516] text-[18px] font-bold  mb-4"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  Total
                </div>
                <div className="bg-[#F6F8F7] w-full rounded-[10px]  flex flex-col p-[16px] gap-2 ">
                  <div className="flex justify-between">
                    <span className="font-bold text-[16px]">Rent Price</span>
                    <span className="text-[#797979] text-[16px]">
                      {pricing.rentPrice}
                      EGP
                    </span>
                  </div>

                  {pricing.discount && Number(pricing.discount) > 0 ? (
                    <div className="flex justify-between">
                      <span className="font-bold text-[16px]">Discount</span>
                      <span className="text-[#797979] text-[16px]">
                        -{Number(pricing.discount).toFixed(2)} EGP
                      </span>
                    </div>
                  ) : null}

                  <div className="flex justify-between text-[16px]">
                    <span className="font-bold">WHT</span>
                    <span className="text-[#797979] ">
                      {Number(pricing.wht).toFixed(2)}
                      EGP
                    </span>
                  </div>

                  <div className="flex justify-between text-[16px]">
                    <span className="font-bold">VAT</span>
                    <span className="text-[#797979] ">
                      {Number(pricing.vat).toFixed(2)}
                      EGP
                    </span>
                  </div>

                  {/* {pricing.securityDepositAmount && Number(pricing.securityDepositAmount) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">Security Deposit Amount</span>
                      <span className="text-[#797979] ">
                        {pricing.securityDepositAmount}
                        EGP
                      </span>
                    </div>
                  ) : null} */}

                  {pricing.vasTotal && Number(pricing.vasTotal) > 0 ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">VAS Total</span>
                      <span className="text-[#797979] ">
                        {pricing.vasTotal}
                        EGP
                      </span>
                    </div>
                  ) : null}

                  {isInsuranceActive ? (
                    <div className="flex justify-between text-[16px]">
                      <span className="font-bold">Insurance </span>
                      <span className="text-[#797979] ">
                        {Number(pricing.securityDepositAmount)}
                        EGP
                      </span>
                    </div>
                  ) : null}

                  <div className="flex flex-col gap-4 mt-2">
                    <span className="font-bold text-[18px]">Total</span>
                    <span
                      className="text-[40px] text-[#011516]"
                      style={{ fontFamily: "gilroy-bold" }}
                    >
                      {pricing.total}
                      EGP
                    </span>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-4 mb-6 flex justify-end gap-10">
              <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
                Cancel
              </Button>
              <Button type="submit" secondary={true}>
                Confirm
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <NewRentContractModalStep2
          fetchData={fetchData}
          data={responseData}
          rentContractModalStep={rentContractModalStep}
          setRentContractModalStep={setRentContractModalStep}
          setOpened={setOpened}
        />
      )}
    </ModalComponent>
  );
};

export default NewRentContractModal;
