import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

const CustomTextField = styled(TextField)({
  backgroundColor: "white",
  fontFamily: "gilroy !important",

  "& .MuiInputBase-root ": {
    height: "50px !important",
    padding:"0 10px"
  },

  "& label.Mui-focused": {
    color: "black",
    fontWeight: "500",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "black",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#EFEFEF",
    },
  },
});
export const TextInput = forwardRef(
  ({ label, multiline, rows, value,height, children, ...props }, ref) => {
    return (
      <>
        <CustomTextField
          sx={{
          height:height,
            width: "100%",
          }}

          value={value}
          id="outlined-basic"
          label={label}
          variant="outlined"
          ref={ref}
          multiline={multiline}
          rows={rows}
          {...props}
        />
        {children}
      </>
    );
  }
);
