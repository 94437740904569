import React, { useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import { Button } from "../../../../components/Button/Button";
import { http } from "../../../../utils/httpCommon";
import { TextInput } from "../../../../components/TextInput/TextInput";
import CustomTextInput from "../../../../components/CustomTextInput/CustomTextInput";
import { useForm } from "react-hook-form";
import useFetchData from "../../../../hooks/FetchData";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DropFile from "../../../../components/DropFile/DropFile";
import { useNavigate, useParams } from "react-router-dom";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import toast, { Toaster } from "react-hot-toast";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const EditVas = () => {
  const params = useParams();
  const { response: vas, loading: loadingVas } = useFetchData(`/vas/${params.id}`);
  const navigator = useNavigate();
  const [mainImage, setMainImages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = yup
    .object({
      name: yup.string().required().typeError("Name a required filed "),
      type: yup.string().required().typeError("VAS type is a required filed "),
      unit: yup.string().required().typeError("VAS unit is a required filed "),
      price: yup.number().integer().required().typeError("Price is a required filed "),
    })
    .required();

  //   get Data

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (passedData) => {
    setIsSubmitting(true);
    var data = new FormData();
    data.append("image", mainImage[0]);
    try {
      let res = await http.post("/image", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      try {
        var data2 = JSON.stringify({
          name: passedData?.name,
          unit: passedData?.unit,
          type: passedData?.type,
          price: passedData?.price,
          image: res.data.url,
        });
        await http.put(`/vas/${params?.id}`, data2, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        setIsSubmitting(false);
        toast.success("VAS has been Edited successfully");
      } catch (error) {
        setIsSubmitting(false);
        toast.error(error.response.data.message);
      }
    } catch (error) {}
  };

  const vasTypes = [{ name: "Product" }, { name: "One time VAS" }];

  useEffect(() => {
    setMainImages([{ name: vas?.image, preview: vas?.image }]);
    setValue("type", vas?.type, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });

    setValue("unit", vas?.unit, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [loadingVas]);

  const vasUnits = [
    { name: "Per piece", id: 1 },
    { name: "Per hour", id: 2 },
    { name: "Per session", id: 3 },
  ];
  return (
    <Layout>
      <Toaster />
      {loadingVas ? (
        <LoadingCircle />
      ) : (
        <div className="flex justify-start mx-10">
          <div className="w-[70%] bg-white p-4 ">
            <div className="flex flex-col">
              <div className="w-full  flex justify-between">
                <span className=" text-[22px] font-extrabold my-2">Add VAS</span>
              </div>
              <span className="text-[16px] font-bold">Add Image</span>
              <span className="text-[16px]">Supported formate: JPEG, PNG</span>
              <br />
            </div>
            <div className="flex gap-[10px] ">
              <div className="min-w-[40%] w-full h-[280px] mb-0 py-2">
                <DropFile
                  image={mainImage}
                  setImage={setMainImages}
                  height={"280px"}
                  width={"600px"}
                  message={"Main image"}
                  bgColor="rgba(50, 179, 190, 0.10)"
                  fit="object-contain"
                />
              </div>
            </div>
            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4 text-[20px] font-extrabold">More info</div>
              <div className="mt-2">
                <TextInput
                  defaultValue={vas?.name}
                  label={"Name*"}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>VAS Type</InputLabel>
                    <Controller
                      control={control}
                      name="type"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="VAS Type"
                          inputRef={ref}
                          defaultValue={vas?.type}
                        >
                          <MenuItem value=""> None </MenuItem>
                          {vasTypes?.map((type) => (
                            <MenuItem key={type.name} value={type.name}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.type?.message}</p>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>VAS Unit</InputLabel>
                    <Controller
                      control={control}
                      name="unit"
                      render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                      }) => {
                        return (
                          <Select
                            sx={{
                              color: "black",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EFEFEF",
                              },
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            variant="outlined"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="VAS Unit"
                            inputRef={ref}
                            defaultValue={vas?.unit}
                          >
                            <MenuItem value=""> None </MenuItem>
                            {vasUnits?.map((unit) => (
                              <MenuItem key={unit.name} value={unit.name}>
                                {unit.name}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.unit?.message}</p>
                </div>
              </div>
              <div className="mt-3">
                <CustomTextInput
                  label={"Price in cash*"}
                  adornment={
                    <>
                      <span className="px-4 border-r-[1px]    font-extrabold text-[16px] text-black">
                        EGP
                      </span>
                    </>
                  }
                  defaultValue={vas?.price}
                  {...register("price", {
                    required: "Price is required",
                  })}
                />
                <p className="text-red-500 text-[16px]">{errors.price?.message}</p>
              </div>
              <div className="mt-6 mb-10 gap-[50px] flex justify-end">
                <Button
                  onClick={() => navigator("/operations/vas")}
                  secondary={true}
                  disabled={isSubmitting}
                  invert={true}
                >
                  <span className="text-[16px]">Cancel</span>
                </Button>
                <Button loading={isSubmitting} secondary={true} type="submit">
                  <span className="text-[16px]">Edit VAS</span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default EditVas;
