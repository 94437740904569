import React, { useState, useCallback, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import whiteTrash from "../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import { useAuthUser } from "react-auth-kit";
import { SelectedLocationContext } from "./../../../Context/LocationProvider";
import { useContext } from "react";

const FacilitiesTable = () => {
  const [searchQuery, setSearchQuery] = React.useState("");

  const auth = useAuthUser();
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  const { selectedLocation } = useContext(SelectedLocationContext);

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;

    if (searchVal?.length > 0) {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.post(
        `facility/all`,
        JSON.stringify({
          name: searchVal,
          page: pageState.page,
          limit: pageState.pageSize,
          location: selectedLocation,
        })
      );

      let filteredData = res.data?.data?.data?.filter((item) => !item?.isDeleted);
      const rows = filteredData?.map((d) => ({
        id: d?._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows,
        total: res.data?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  //fetching pagenated Data
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));

    const res = await http.post(
      `facility/all`,
      JSON.stringify({
        name: searchValues,
        page: pageState.page,
        limit: pageState.pageSize,
        location: selectedLocation,
      })
    );

    let filteredData = res.data?.data?.data?.filter((item) => !item?.isDeleted);
    const rows = filteredData?.map((d) => ({
      id: d?._id,
      ...d,
    }));

    setPageState((old) => ({
      ...old,
      isLoading: false,
      data: rows,
      total: res.data?.data?.count,
    }));
  };

  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation]);

  const navigator = useNavigate();
  const columns = [
    {
      field: "Images",
      headerName: "",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          <Link to={`/inventory/facility/${params.row.id}`}>
            <div className="flex gap-[10px] py-2 items-center">
              {params.row.mainImage ? (
                <img
                  className="w-[70px] object-cover h-[70px] rounded-[50px]"
                  src={params.row?.mainImage}
                  alt={params.row?.name}
                />
              ) : (
                <span className="h-[70px]"></span>
              )}
            </div>
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,

      renderCell: (params) => {
        return (
          <Link
            to={`/inventory/facility/${params.row.id}`}
            className="flex items-center  font-semibold  text-[16px]  py-2 my-1 hover:text-[#34B2C0]"
          >
            {params?.row?.name}
          </Link>
        );
      },
    },

    {
      field: "availability",
      headerName: "Availability",
      width: 300,

      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-[130px]">
            {params.row.isAvailable ? (
              <div className=" text-[#34B2C0]  text-[15px]  bg-[#3bb2c0] bg-opacity-20 font-semibold p-2 flex  justify-center items-center rounded-[25px]   ">
                Available
              </div>
            ) : (
              <div className=" text-[#797979] text-[15px] bg-[#EFEFEF]  font-semibold p-2 flex  justify-center items-center rounded-[25px]  ">
                Not Available
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      width: 250,

      renderCell: (params) => {
        return (
          <>
            {auth()?.role !== "Land Lord" && (
              <div className="flex flex-col gap-[15px]">
                <button
                  onClick={() => {
                    handelEdit(params.row.id);
                  }}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                  </div>
                  Edit
                </button>
                {auth().role === "Branch Manager" || auth().role === "Area Manager" ? null : (
                  <button
                    onClick={() => {
                      handleDelete(params.row);
                    }}
                    className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={whiteTrash} alt="edit" />
                    </div>
                    Delete
                  </button>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  // handle edit
  const handelEdit = (id) => {
    navigator(`/inventory/edit-facility/${id}`);
  };

  //handle delete
  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      await http.delete(`/facility/${deletedItem?.id}`);
      setOpenedDelete(false);
      fetchData();
      toast.success("Facility has been deleted successfully");
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  //handle add new amenity
  const handleActionButton = () => {
    navigator("/inventory/add-facility");
  };

  return (
    <>
      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        loading={deleteLoading}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="Please Note that the facility is currently under a utilization by a service now or in the near future please confirm the deletion of all pending and active services booked."
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        fetchData={fetchData}
        // keys={["id", "name", "phone", "email", "location", "role"]}
        title={"Facilities"}
        actionButton={"Add Facility"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
        removeActionButton={auth().role === "Experience Officer"}
      />
    </>
  );
};

export default FacilitiesTable;
