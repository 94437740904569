import React, { useEffect, useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import useFetchData from "../../../../hooks/FetchData";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";
import archiveImg from "../../../../assets/icons/archive.png";
import phoneIcon from "../../../../assets/icons/call.png";
import atIcon from "../../../../assets/icons/@.png";
import plusIcon from "../../../../assets/icons/whitePlus.png";
import MqrCoin from "../../../../assets/icons/mqrCoin.png";
import notificationIcon from "../../../../assets/icons/notificationIcon.png";
// import NewCheckIn from "./NewCheckIn";
// import ArchiveClient from "./ArchiveClient";
// import SendClientNotification from "./SendClientNotification";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import downloadIcon from "../../../../assets/icons/downloadIcon.png";
import fileIcon from "../../../../assets/icons/fileIcon.png";
import ActivityLogs from "../../../../components/ActivityLogs/ActivityLogs";
import RentContract from "../../../../components/RentContract/RentContract";
import ArchiveCompany from "./ArchiveCompany";
import SendCompanyNotification from "./SendCompanyNotification";
import DeleteCompany from "./DeleteClient";
import Trash from "../../../../assets/icons/DeleteIcon.png";
import { useAuthUser } from "react-auth-kit";
import ModalComponent from "../../../../components/Modal/Modal2";
import { Button } from "../../../../components/Button/Button";

const Company = () => {
  const params = useParams();
  const { response, error, loading, fetchData } = useFetchData(`/company/${params.id}`);

  const auth = useAuthUser();

  const [activeTab, setActiveTab] = useState(0);

  const navigator = useNavigate();
  // const { userData, getUserData } = useContext(UserContext);

  useEffect(() => {
    // getUserData();
  }, []);
  const taps = [
    { name: "Company info", index: 0 },
    { name: "Coin Bank", index: 1 },
    { name: "Members", index: 2 },
    { name: "Rent Contract", index: 3 },
    { name: "Activity log", index: 4 },
  ];

  const handleActiveLink = (index) => {
    setActiveTab(index);
  };

  //handle Archive
  const [openArchiveCompanyModal, setOpenArchiveCompanyModal] = useState(false);

  const [openSendNotificationModal, setOpenSendNotificationModal] = useState(false);

  //handle members

  const [data, setData] = useState([]);

  const columns = [
    {
      name: "image",
      label: "",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastcheckin",
      label: "Last check in",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);
  const handleActionButton = () => {
    setOpenAddMemberModal(true);
  };

  //handle search
  const [searchQuery, setSearchQuery] = React.useState("");
  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let updatedDate;
  let keys = ["name"];
  const search = () => {
    updatedDate = data?.filter((item) =>
      keys.some((key) => item[key]?.toLowerCase().includes(searchQuery.toLowerCase()))
    );
    return updatedDate;
  };
  useEffect(() => {
    setData(search(data));
  }, [searchQuery]);

  const [openDeleteCompanyModal, setOpenDeleteCompanyModal] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (image) => {
    setModalIsOpen(true);
    setSelectedImage(image);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage("");
  };

  return (
    <>
      <Layout>
        {!loading ? (
          <>
            <ModalComponent
              opened={modalIsOpen}
              setOpened={setModalIsOpen}
              onClose={closeModal}
              size="40%"
            >
              <div className="flex justify-center items-center flex-col w-full">
                <img src={selectedImage} alt={selectedImage} />

                <div className="flex  gap-[30px] py-6">
                  <Button secondary={true} invert={true} onClick={closeModal}>
                    Close
                  </Button>
                  <Button onClick={() => window.open(selectedImage, "_blank")}>Download</Button>
                </div>
              </div>
            </ModalComponent>
            <ArchiveCompany
              opened={openArchiveCompanyModal}
              setOpened={setOpenArchiveCompanyModal}
              name={response?.name}
              id={response?._id}
            />
            <DeleteCompany
              opened={openDeleteCompanyModal}
              setOpened={setOpenDeleteCompanyModal}
              name={response?.name}
              id={response?._id}
            />
            <SendCompanyNotification
              opened={openSendNotificationModal}
              setOpened={setOpenSendNotificationModal}
              res={response}
            />
            <div className="bg-white m-4 gap-[50px] rounded-[20px] flex py-4 px-6   w-[90%] min-h-[400px] ">
              <div className="flex flex-col h-full  ">
                {response?.logo && (
                  <img
                    className="w-full h-[250px]  object-cover rounded-[20px] "
                    src={response?.logo}
                    alt={response?.name}
                  />
                )}

                <div className="mt-4 text-[24px] font-bold  " style={{ fontFamily: "gilroy-bold" }}>
                  {response?.name}
                </div>
              </div>

              <div className="flex flex-col h-full w-full  overflow-x-auto  ">
                {auth().role !== "Land Lord" && (
                  <div className="flex justify-between items-center w-full">
                    <div className="flex gap-[40px] my-2">
                      <button
                        onClick={() => navigator(`/community/edit-company/${response._id}`)}
                        className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                      >
                        <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                          <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                        </div>
                        Edit Profile
                      </button>

                      {auth().role === "Experience Officer" ||
                      auth().role === "Branch Manager" ||
                      auth().role === "Area Manager" ? null : (
                        <>
                          <button
                            onClick={() => setOpenArchiveCompanyModal(true)}
                            className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={archiveImg} alt="Archive" />
                            </div>
                            Archive
                          </button>
                          {/* <button
                      onClick={() => setOpenSendNotificationModal(true)}
                      className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                    >
                      <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                        <img
                          className=" w-[18px] "
                          src={notificationIcon}
                          alt="notificationIcon"
                        />
                      </div>
                      Send Notifications
                    </button> */}

                          <button
                            onClick={() => setOpenDeleteCompanyModal(true)}
                            className="text-[16px] group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                          >
                            <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                              <img className=" w-[18px] " src={Trash} alt="Archive" />
                            </div>
                            Delete
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {/* Taps */}
                <div className="my-2  flex    text-[14px] font-semibold     ">
                  {taps.map((t, index) => (
                    <button
                      onClick={() => handleActiveLink(index)}
                      className={` ${
                        activeTab === t.index
                          ? "text-black border-b-[#32B3BE] border-b-[6px]"
                          : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                      } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                      key={index}
                    >
                      <div className="px-1 w-[120px]">{t.name}</div>
                    </button>
                  ))}
                </div>

                <div>
                  {activeTab === 0 ? (
                    // Personal info
                    <div className="flex flex-col my-3">
                      {response?.onBoarded ? (
                        <div
                          style={{ fontFamily: "gilroy-bold" }}
                          className=" text-[16px] mb-2 text-[#34B2C0] w-fit bg-[#3bb2c0] bg-opacity-20  p-2 flex  justify-center items-center rounded-[25px]   "
                        >
                          On the App
                        </div>
                      ) : (
                        <div className="text-[16px] font-semibold bg-[#EFEFEF] w-fit p-2 rounded-[20px] my-2 text-[#797979] ">
                          Not yet on the app
                        </div>
                      )}{" "}
                      {response?.industry?.name && (
                        <div className="font-semibold my-2">{response?.industry?.name}</div>
                      )}
                      <div
                        style={{
                          borderBottom: " 2px solid rgba(228, 228, 228, 0.59)",
                        }}
                      />
                      {response?.phone && (
                        <div className="flex items-start my-2 gap-[10px]">
                          <img className="w-[15px] mt-1" src={phoneIcon} alt={response?.phone} />
                          <div className="flex flex-col">
                            <span className="font-semibold">{response?.phone}</span>{" "}
                            {response?.phoneVerified ? (
                              <span className="text-[#00AD3B] font-medium">*Status Verified </span>
                            ) : (
                              <span className="text-[#B3B3B3] text-[16px] font-medium">
                                *Pending Verification
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {response?.email && (
                        <div className="flex items-start my-2 gap-[10px]">
                          <img className="w-[15px] mt-1" src={atIcon} alt={response?.email} />
                          <div className="flex flex-col">
                            <span className="font-semibold">{response?.email}</span>
                            {response?.emailVerified ? (
                              <span className="text-[#00AD3B] font-medium">*Status Verified </span>
                            ) : (
                              <span className="text-[#B3B3B3] text-[16px] font-medium">
                                *Pending Verification
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      <div
                        style={{
                          borderBottom: " 2px solid rgba(228, 228, 228, 0.59)",
                        }}
                      />
                      <div className="my-2">
                        <div className="flex gap-[10px] w-full flex-wrap">
                          <div className="mt-2 flex items-center gap-3  border-[1px] border-[#EFEFEF] rounded-[20px] w-fit p-2">
                            <div className="bg-[#3bb2c0]  bg-opacity-20 w-[72px] h-[72px] flex items-center justify-center rounded-[20px] ">
                              <img className="w-[23px] " src={fileIcon} alt="file" />
                            </div>

                            <div>
                              <span className="text-[16px]" style={{ fontFamily: "gilroy-bold" }}>
                                Document
                              </span>
                              <div className="mt-2 text-[16px] flex">
                                <button
                                  onClick={() => openModal(response?.registrationDocument)}
                                  className=" mr-2 text-[#8E8E8E] "
                                >
                                  View
                                </button>
                                <button
                                  onClick={() =>
                                    window.open(response?.registrationDocument, "_blank")
                                  }
                                  className="flex  items-center font-semibold gap-2 text-[#32B3BE]"
                                >
                                  Download
                                  <img className="w-[14px] " src={downloadIcon} alt="download" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeTab === 1 ? (
                    <div>
                      <div className="mt-6 ">
                        <div
                          className="text-[18px] flex gap-10 "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Coin Bank
                          <button className="text-[#34B2C0]">Purchase Coins</button>
                        </div>
                        <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                          <span className=" text-[#797979] font-semibold">
                            Current Coin Balance
                          </span>
                          <div className="flex gap-[10px] items-center mt-2">
                            <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                            <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                              {response?.publicCoinBalance
                                ? response?.publicCoinBalance
                                : response?.publicCoinBalance}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 ">
                        <div
                          className="text-[18px] flex gap-10 "
                          style={{ fontFamily: "gilroy-bold" }}
                        >
                          Custom Balances
                        </div>
                        <div className="flex gap-10">
                          <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                            <span className=" text-[#797979] font-semibold">Admins’ Balance</span>
                            <div className="flex gap-[10px] items-center mt-2">
                              <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                              <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                                {response?.privateCoinBalance
                                  ? response?.privateCoinBalance
                                  : response?.privateCoinBalance}
                              </span>
                            </div>
                          </div>

                          <div className=" border-[1px] border-[#79797933] w-fit my-3 p-5 rounded-[10px] ">
                            <span className=" text-[#797979] font-semibold">Spokes’ Balance</span>
                            <div className="flex gap-[10px] items-center mt-2">
                              <img className="w-[40px] " src={MqrCoin} alt="Mqr Coin" />
                              <span className="text-[40px]" style={{ fontFamily: "gilroy-bold" }}>
                                {response?.coins ? response?.coins : response?.coins}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : activeTab === 2 ? (
                    <div className="mt-6 ">
                      <Link
                        to={"/community/clients/new-client"}
                        className=" mb-4 group font-semibold text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                      >
                        <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                          <img className=" w-[18px] " src={plusIcon} alt="Add" />
                        </div>
                        Add Member
                      </Link>
                      <div>
                        {/* <DataTable
                          // style={{boxShadow:"none"}}
                          checkbox={false}
                          data={data}
                          columns={columns}
                          title={"Members"}
                          actionButton={"Make Admin"}
                          handleActionButton={handleActionButton}
                          searchQuery={searchQuery}
                          handleChange={handleChange}
                        /> */}
                      </div>
                    </div>
                  ) : activeTab === 3 ? (
                    <div className="mt-6 ">
                      <RentContract />
                    </div>
                  ) : (
                    <div className="mt-6 ">
                      <ActivityLogs />
                    </div>
                  )}
                </div>
              </div>
            </div>{" "}
          </>
        ) : (
          <LoadingCircle />
        )}
      </Layout>
    </>
  );
};

export default Company;
