import React, { useEffect } from "react";
import { Layout } from "../../components/Layout/Layout";
import { useState } from "react";
import Transactions from "./Transactions/Transactions";
import Invoices from "./Invoices/Invoices";
import PromoCodes from "./PromoCodes/PromoCodes";
import ReconsiliationsTable from "./Transactions/RecpnsiliationsTable";
import TotalsTable from "./Totals/TotalsTable";
import { useAuthUser } from "react-auth-kit";
import { useLocation, useNavigate } from "react-router-dom";

const Finance = () => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigator = useNavigate();
  const auth = useAuthUser();
  const taps = [
    {
      name: "Transactions",
      component: <Transactions />,
      index: 0,
      blacklist: [],
      path: "/finance",
    },
    {
      name: "Invoices",
      component: <Invoices />,
      index: 1,
      blacklist: [],
      path: "/finance/invoices",
    },
    {
      name: "Reconsiliations",
      component: <Reconsiliations />,
      index: 2,
      blacklist: [],
      path: "/finance/reconsiliations",
    },
    { name: "Total", component: <TotalsTable />, index: 3, blacklist: [], path: "/finance/total" },
    {
      name: "Promo codes",
      component: <PromoCodes />,
      index: 4,
      blacklist: [],
      path: "/finance/promo-codes",
    },
  ];

  const handleActiveLink = (index) => {
    setActiveTab(index);
    const tabName = taps[index].name.toLowerCase().replace(" ", "-");
    const newPath = `/finance/${tabName}`;
    navigator(newPath);
  };

  useEffect(() => {
    const currentTab = taps.findIndex((tab) => location.pathname === tab.path);
    if (currentTab !== -1) {
      setActiveTab(currentTab);
    }
  }, [location]);

  return (
    <Layout>
      <div className="mt-[0px] mx-[2%]">
        <div className="text-[16px] font-semibold text-[#797979]">Finance</div>
        <div className="text-[32px] font-semibold" style={{ fontFamily: "gilroy-bold" }}>
          {taps[activeTab].name}
        </div>
        <div className="mt-4 mb-0 flex w-full max-w-[500px] text-[18px] font-semibold">
          {taps
            .filter((tap) => !tap.blacklist.includes(auth()?.role))
            .map((t, index) => (
              <button
                onClick={() => handleActiveLink(index)}
                className={` ${
                  activeTab === t.index
                    ? "text-black border-b-[#32B3BE] border-b-[6px]"
                    : " text-[#8E8E8E]  border-b-[#EFEFEF]  border-b-[6px]"
                } flex   font-semibold  w-full transition-all duration-150 
                  underline-offset-8 
                  `}
                key={index}
              >
                <div className="px-2 w-[135px]">{t.name}</div>
              </button>
            ))}
        </div>
        <div>
          {activeTab === 0 &&
          (window.location.pathname === "/finance" ||
            window.location.pathname === "/finance/transactions") ? (
            <Transactions />
          ) : activeTab === 1 && window.location.pathname === "/finance/invoices" ? (
            <Invoices />
          ) : activeTab === 2 && window.location.pathname === "/finance/reconsiliations" ? (
            <Reconsiliations />
          ) : activeTab === 3 && window.location.pathname === "/finance/total" ? (
            <TotalsTable />
          ) : activeTab === 4 && window.location.pathname === "/finance/promo-codes" ? (
            <PromoCodes />
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default Finance;

export function Reconsiliations() {
  return <ReconsiliationsTable />;
}
