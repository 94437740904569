import React, { useCallback, useContext, useEffect, useState } from "react";
// import DataTable from "../../../components/DataTable/DataTable";
import deleteIcon from "../../../assets/icons/whiteTrash.png";
import phoneICon from "../../../assets/icons/call.png";
import { Link } from "react-router-dom";
import ReconsiliationRequest from "./ReconsiliationModal";
import VoidRequest from "./VoidRequest";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";

const ReconsiliationsTable = () => {
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };
  const { selectedLocation } = useContext(SelectedLocationContext);
  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    try {
      let searchVal = filterModel?.items[0]?.value;

      if (searchVal?.length > 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));

        const res = await http.get(
          `/transactions/reconsiliations?location=${selectedLocation}&page=${pageState.page}&limit=${pageState.pageSize}`
        );

        const rowsData = res.data?.data?.list?.map((d) => ({
          id: d._id,
          ...d,
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rowsData,
          //get from another api
          total: res?.data?.count,
        }));
      } else {
        debounced();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  }, []);

  const columns = [
    {
      field: "amount",
      headerName: "Amount",
      width: 180,
      filterable: false,
      renderCell: (params) => {
        return <div className="flex py-2 items-center text-[16px] ">{params.row.amount}</div>;
      },
    },
    {
      field: "location",
      headerName: "Locations",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex py-2 items-center text-[16px] ">{params.row.location?.name}</div>
        );
      },
    },
    {
      field: "admin",
      headerName: "Admin",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex py-2 items-center text-[16px] whitespace-nowrap">
            {params.row.admin.name}
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="text-[#797979] flex py-2 items-center  font-extrabold text-[16px]"></div>
        );
      },
      // editable: true,
    },
  ];

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.get(
        `/transactions/reconsiliations?location=${selectedLocation}&page=${pageState.page}&limit=${pageState.pageSize}`
      );
      const rowsData = res.data?.data?.list.map((d) => ({
        id: d._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        //get from another api
        total: res?.data?.count,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation]);

  //handle add new
  const [openAddModal, setOpenAddModal] = useState(false);
  const handleActionButton = () => {
    setOpenAddModal(true);
  };

  return (
    <div>
      <ReconsiliationRequest
        opened={openAddModal}
        setOpened={setOpenAddModal}
        fetchData={fetchData}
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        title={"Reconsiliations"}
        actionButton={"Reconsiliation request"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </div>
  );
};

export default ReconsiliationsTable;
