import moment from "moment";
import React, { useState } from "react";
import RightArrow from "../../../assets/icons/RightButton.svg";
import LeftArrow from "../../../assets/icons/LeftButton.svg";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import LeftBlackArrow from "../../../assets/icons/LeftBlackArrow.svg";
import RightBlackArrow from "../../../assets/icons/RightBlackArrow.svg";

const MonthView = ({ facilities, appointmentsData, fetchAppointmentsData }) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleChangeDate = (newValue) => {
    setCurrentDate(new Date(newValue));
  };

  const getResources = () => {
    return facilities?.data?.map((facility) => ({
      id: facility?._id,
      name: facility?.name,
      ...facility,
    }));
  };

  const appointments = appointmentsData;

  const generateDates = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const dates = [];
    let currentDay = firstDay;

    while (currentDay <= lastDay) {
      dates.push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }

    return dates;
  };

  const handlePreviousMonth = () => {
    const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1);
    setCurrentDate(prevMonth);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1);
    setCurrentDate(nextMonth);
  };

  // Resources
  const resourcesPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const handleNextResources = () => {
    const totalPages = Math.ceil(facilities?.data?.length / resourcesPerPage);
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevResources = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const startResourceIndex = currentPage * resourcesPerPage;
  const endResourceIndex = startResourceIndex + resourcesPerPage;
  const visibleResources = facilities?.data?.slice(startResourceIndex, endResourceIndex) || [];

  // renderAppointments
  const renderAppointments = (resourceId, date) => {
    const filteredAppointments = appointments?.filter(
      (appointment) =>
        appointment?.roomId === resourceId &&
        moment(appointment?.startDate).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")
    );

    return filteredAppointments?.map((appointment) => (
      <div
        key={appointment?.id}
        className="bg-white  text-[16px]  w-full flex-col rounded-[10px]  flex-wrap flex  items-start  gap-2 py-[10px] px-2  border-t-[0.5px] border-t-[#E4E4E4] "
      >
        <div className="flex gap-2 items-center">
          <div className="bg-[#D9D9D9] w-[10px]  h-[10px] rounded-full"></div>
          {appointment?.allDay && <div className="text-[#34B2C0]">Fully booked Day</div>}
          <div>
            {moment(appointment?.startTime * 1000).format("LT")} -{" "}
            {moment(appointment?.endTime * 1000).format("LT")}
          </div>
        </div>

        <div className="flex gap-2">
          <div className="text-[#797979] text-[14px]">Booked by</div>

          <Link
            className="hover:text-[#34B2C0] text-[14px]"
            to={`/community/client/${appointment?.user?._id}`}
          >
            {appointment?.user?.name}
          </Link>
        </div>
      </div>
    ));
  };

  const renderCalendar = () => {
    const resources = getResources();
    const dates = generateDates();

    return (
      <div className="relative">
        <div className="relative w-full flex justify-center items-center">
          <div className="flex   items-center -top-10 absolute justify-center  min-w-[300px] left-[40%] right-[50%] mb-[20px] mt-[0px]">
            <button
              className="text-[32px] w-[44px] flex justify-center items-center h-[44px] hover:bg-gray-200 rounded-full transition duration-75"
              onClick={handlePreviousMonth}
            >
              <img src={LeftArrow} alt="Back" className="w-[12px]" />
            </button>
            <button
              className="text-[32px] w-[44px] flex justify-center items-center h-[44px] hover:bg-gray-200 rounded-full transition duration-75"
              onClick={handleNextMonth}
            >
              <img src={RightArrow} alt="next" className="w-[12px]" />
            </button>

            <div className="relative">
              <div className=" text-[20px] text-[#011516]  relative flex w-fit">
                <div
                  className=" text-[20px] text-[#011516]  relative"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  {new Date(currentDate).toLocaleString("default", {
                    month: "long",
                  })}
                  {"  , "}
                  {new Date(currentDate).getFullYear()}
                </div>
                <div className="relative">
                  <div className="absolute -bottom-2 left-0">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        views={["year", "month"]}
                        label=""
                        placeholder=""
                        value={currentDate}
                        onChange={handleChangeDate}
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              width: "100%",
                              color: "#000000cc",
                              "& .MuiFormControl-root ": {
                                borderColor: "none",
                              },
                              "& .MuiOutlinedInput-input": {
                                visibility: "hidden !important",
                              },
                              "& .MuiInputBase-root": {
                                "& fieldset": {
                                  border: "none !important",
                                },
                                "&:hover fieldset": {
                                  border: "none !important",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "none !important",
                                },
                              },
                              "& .MuiFormLabel-root ": {
                                display: "none",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
            {/* New Date */}
          </div>
        </div>

        <div className="relative mt-3">
          <div className="  ">
            <table className="w-full  ">
              <thead className="">
                <tr>
                  <th className="flex gap-2 items-center  justify-center mt-1">
                    {currentPage === 0 ? (
                      <div className="min-w-[40px] p-1"></div>
                    ) : (
                      <button
                        className={`  ${
                          currentPage === 0
                            ? ""
                            : "hover:bg-[#878787] hover:ring-2 hover:ring-white"
                        } min-w-[40px] rounded-full min-h-[40px] flex justify-center items-center  transition-all   `}
                        onClick={handlePrevResources}
                        disabled={currentPage === 0}
                      >
                        <img src={LeftBlackArrow} alt="Prev" className="w-[14px]" />
                      </button>
                    )}

                    {currentPage === Math.ceil(facilities?.data?.length / resourcesPerPage) - 1 ? (
                      <div className="min-w-[40px] p-1"></div>
                    ) : (
                      <button
                        className={`    ${
                          currentPage === Math.ceil(facilities?.data?.length / resourcesPerPage) - 1
                            ? ""
                            : "hover:bg-[#878787]  hover:ring-2 hover:ring-white"
                        } min-w-[40px] rounded-full min-h-[40px] flex justify-center items-center transition-all `}
                        onClick={handleNextResources}
                        disabled={
                          currentPage === Math.ceil(facilities?.data?.length / resourcesPerPage) - 1
                        }
                      >
                        <img src={RightBlackArrow} alt="Next" className="w-[14px]" />
                      </button>
                    )}
                  </th>
                  {visibleResources.map((resource, index) => (
                    <th
                      key={resource.id}
                      className={`text-left  ${
                        visibleResources?.length > 1 ? "min-w-[130px] w-fit" : "w-full min-w-full"
                      } h-[60px] font-semibold bg-[white] shadow-lg`}
                    >
                      <div className="flex items-center  text-[16px] gap-3 w-full ">
                        {resource?.mainImage && (
                          <LazyLoadImage
                            height={70}
                            width={70}
                            src={resource?.mainImage}
                            alt={resource.name}
                            className={` object-cover w-[70px] h-[50px] xl:w-[70px] xl:h-[60px] ${
                              index === 0 ? " rounded-t-[10px] " : "rounded-tr-[10px]"
                            } `}
                          />
                        )}
                        {resource.name}
                      </div>
                    </th>
                  ))}
                  {Array(resourcesPerPage - visibleResources.length)
                    .fill(null)
                    .map((_, index) => (
                      <th
                        key={index}
                        className={`${
                          visibleResources?.length > 1 ? "min-w-[130px] w-fit" : "w-full min-w-full"
                        } h-[60px]`}
                      ></th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {/* <tr className="">
                  <td className="rounded-t-[10px] "></td>
                  {resources?.map((resource, index) => (
                    <th
                      className=" text-left min-w-[130px]   w-fit  h-[60px]  font-semibold bg-[white] shadow-lg	"
                      key={resource.id}
                    >
                      <div className="flex items-center  text-[16px] gap-3 w-full ">
                        {resource?.mainImage && (
                          <LazyLoadImage
                            height={70}
                            width={70}
                            src={resource?.mainImage}
                            alt={resource.name}
                            className={` object-cover w-[70px] h-[50px] xl:w-[70px] xl:h-[60px] ${
                              index === 0
                                ? " rounded-t-[10px] "
                                : "rounded-tr-[10px]"
                            } `}
                          />
                        )}
                        {resource.name}
                      </div>
                    </th>
                  ))}
                </tr> */}

                {dates?.map((date) => (
                  <tr className="" key={date?.getDate()}>
                    <td className="border-[1px] border-[#E4E4E4] h-[100px] min-w-[120px] text-center text-[#797979]  bg-[#F8F9FA] text-[16px] font-bold">
                      {moment(date)?.format("DD/MM/YYYY")}
                    </td>
                    {visibleResources.map((resource) => (
                      <td
                        className="bg-[#F8F9FA] max-w-[250px] border-[0.5px] border-[#E4E4E4] h-full hover:bg-gray-100 transition-all duration-100 "
                        key={`${resource._id}-${date?.getDate()}`}
                      >
                        <div className="flex flex-col justify-start h-full min-h-[70px] w-full">
                          {renderAppointments(resource._id, date)}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderCalendar()}</div>;
};

export default MonthView;
