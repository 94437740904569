import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import whiteTrash from "../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import { toast } from "react-hot-toast";
import LoadingCircle from "./../../../components/LoadingCircle/LoadingCircle";
import { http } from "../../../utils/httpCommon";
import { useAuthUser } from "react-auth-kit";
const LocationsTable = ({ response, fetchData, loading }) => {
  const navigator = useNavigate();
  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const auth = useAuthUser();
  const columns = [
    {
      field: "Images",
      headerName: "",
      minWidth: 200,
      filterable: false,
      maxWidth: 250,
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/inventory/location/${params.row.id}`}>
            <div className="flex gap-[10px] items-center p-2">
              {params?.row?.mainImage ? (
                <img
                  className="w-[75px] object-cover h-[75px] rounded-[60px]"
                  src={params?.row?.mainImage}
                  alt={params?.row?.name}
                />
              ) : (
                <span className="h-[70px]"></span>
              )}
            </div>
          </Link>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      maxWidth: 320,
      renderCell: (params) => {
        return (
          <Link to={`/inventory/location/${params.row.id}`}>
            <div className="flex ">
              <span className=" hover:text-[#32B3BE]   font-bold transition-all  text-[16px] ">
                {params?.row?.name}
              </span>
            </div>
          </Link>
        );
      },
    },

    {
      field: "leader",
      headerName: "Leader",
      width: 180,
      renderCell: (params) => {
        return (
          <Link
            className="flex gap-[10px] items-center"
            to={`/settings/admin/${params?.row?.teamLeader?._id}`}
          >
            <span className=" hover:text-[#32B3BE]   font-bold transition-all  text-[16px] ">
              {params?.row?.teamLeader?.name}
            </span>
          </Link>
        );
      },
    },

    {
      field: "numberOfFacilities",
      headerName: "Number of facilities",
      width: 200,

      renderCell: (params) => {
        return (
          <div className="flex w-full justify-center items-center">
            <span className=" font-semibold text-[16px] ">{params?.row?.facilityCount}</span>
          </div>
        );
      },
    },

    {
      headerName: "Actions",
      field: "actions",
      minWidth: 250,
      filterable: false,

      maxWidth: 300,
      renderCell: (params) => {
        return (
          <>
            {auth().role !== "Land Lord" && (
              <div className="flex flex-col gap-[15px]">
                <button
                  onClick={() => {
                    handelEdit(params.row?.id);
                  }}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                  </div>
                  Edit
                </button>
                <button
                  onClick={() => {
                    handleDelete(params.row);
                  }}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteTrash} alt="edit" />
                  </div>
                  Delete
                </button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const handleActionButton = () => {
    navigator("/inventory/locations/add-location");
  };

  //handle delete

  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const handleSubmitDelete = async () => {
    try {
      await http.delete(`/location/${deletedItem?.id}`);
      toast.success("Location deleted successfully");
      fetchData();
    } catch (error) {
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  const handelEdit = (id) => {
    navigator(`/inventory/edit-location/${id}`);
  };

  return (
    <>
      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="Please Note that the location is currently under a utilization by a service now or in the near future please confirm the deletion of all pending and active services booked."
      />
      {loading ? (
        <LoadingCircle />
      ) : (
        <Table
          data={response}
          columns={columns}
          keys={["name", "leader", "numberOfFacilities"]}
          title={"Locations"}
          actionButton={"Add Location"}
          handleActionButton={handleActionButton}
        />
      )}
    </>
  );
};

export default LocationsTable;
