import React, { useState, useCallback, useEffect, useContext } from "react";
import { http } from "../../../utils/httpCommon";
import { Link } from "react-router-dom";
import Table from "../../../components/DataGrid/Table";
import { debounce } from "lodash";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import NewAddCreditModal from "./NewAddCreditModal";
import { Switch } from "@mui/material";

const Credit = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const { selectedLocation } = useContext(SelectedLocationContext);

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };
  const [isClient, setIsClient] = useState(true);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;

    if (searchVal?.length > 0) {
      const res = await http.get(
        `/credit/?page=${pageState.page}&limit=${
          pageState.pageSize
        }&name=${searchQuery}&creditType=${
          isClient ? "client" : "company"
        }&locationId=${selectedLocation}`
      );
      const rowsData = res?.data?.creditAccounts?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  const fetchData = async (searchQuery = "") => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));
    try {
      const res = await http.get(
        `/credit/?page=${pageState.page}&limit=${
          pageState.pageSize
        }&name=${searchQuery}&creditType=${
          isClient ? "client" : "company"
        }&locationId=${selectedLocation}`
      );
      const rowsData = res?.data?.creditAccounts?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.count,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize, selectedLocation, isClient]);

  const debounced = useCallback(debounce(fetchData, 700), []);

  const columns = [
    {
      field: "name",
      headerName: "Client/Company Name",
      width: 400,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center py-2">
            <Link
              to={`/operations/rent-contract/${params.row.id}`}
              className=" object-cover h-[66px]  flex justify-center items-center font-semibold text-[16px] hover:text-[#34B2C0] transition-all"
            >
              {params?.row?.name}
            </Link>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Credit Amount",
      width: 220,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="  font-medium hover:text-[#34B2C0] transition-all text-[16px]   ">
              {Number(params?.row?.wallet?.credit).toFixed(2)}
            </span>
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    setOpenRentContractModal(true);
  };
  const [openRentContractModal, setOpenRentContractModal] = useState(false);

  return (
    <>
      <NewAddCreditModal
        opened={openRentContractModal}
        setOpened={setOpenRentContractModal}
        refetch={fetchData}
      />

      <div className="flex items-center gap-4 my-4">
        <span>Client</span>
        <Switch checked={!isClient} onChange={() => setIsClient(!isClient)} />
        <span>Company</span>
      </div>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState?.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        keys={["id", "name", "Amount"]}
        title={"Credit List "}
        actionButton={"Add Credit Balance"}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default Credit;
