import React, { useState } from "react";
import { Layout } from "../../../../components/Layout/Layout";
import { useParams, useNavigate } from "react-router-dom";
import useFetchData from "../../../../hooks/FetchData";
import VerticalCarousel from "../../../../components/VerticalCarousel/VerticalCarousel";
import whiteTrash from "../../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../../assets/icons/whiteEdit.png";
import { http } from "../../../../utils/httpCommon";
import DeleteModal from "../../../../components/DeleteModal/DeleteModal";
import coin from "../../../../assets/coin.png";
import LoadingCircle from "../../../../components/LoadingCircle/LoadingCircle";
import { useAuthUser } from "react-auth-kit";

const Location = () => {
  const navigator = useNavigate();
  const { id } = useParams();
  const { response, error, loading, fetchData } = useFetchData(`/location/${id}`);
  const auth = useAuthUser();
  let slides = [];
  let data = [];
  if (!loading) {
    slides = [...response?.gallery];
    slides?.push(response?.mainImage);
  }
  data = slides.map((s) => ({ id: s, introline: s }));

  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const handleSubmitDelete = async () => {
    await http.delete(`/facility/${deletedItem?._id}`);
    setOpenedDelete(false);
    fetchData();
    navigator("/");
  };

  return (
    <>
      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="Please Note that the location is currently under a utilization by a service now or in the near future please confirm the deletion of all pending and active services booked."
      />
      <Layout>
        {!loading ? (
          <div className="flex  gap-[20px] ">
            {response?.mainImage ? (
              <div className="w-[55%] ">
                <div className="flex">
                  <VerticalCarousel data={data} />
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="w-[45%] flex flex-col gap-[15px] mt-[10px] ">
              {auth().role !== "Land Lord" && (
                <div className="flex gap-[50px]">
                  <button
                    onClick={() => navigator(`/inventory/edit-location/${id}`)}
                    className="group text-[#686868] flex items-center text-[16px]  hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[34px] h-[34px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                    </div>
                    Edit Location
                  </button>

                  <button
                    onClick={() => handleDelete(response)}
                    className="group text-[#686868] flex items-center text-[16px] hover:text-[#32B3BE] transition-all "
                  >
                    <div className="flex justify-center items-center bg-[#686868] w-[34px] h-[34px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                      <img className=" w-[18px] " src={whiteTrash} alt="edit" />
                    </div>
                    Delete Location
                  </button>
                </div>
              )}
              <div className="text-[30px] font-bold">{response?.name}</div>
              <div className="flex  gap-[40px] text-[18px]">
                <div>
                  Area :
                  <br />
                  <span className="font-semibold">{response?.area} m&#178;</span>
                </div>
                <div>
                  Net Area :
                  <br />
                  <span className="font-semibold">{response?.netArea} m&#178;</span>
                </div>
              </div>
              <hr />
              <div className="my-1 flex flex-col gap-[10px] text-[18px]  ">
                <div>Working hours</div>
                <div className="font-semibold">
                  {new Date(response?.workingHours?.start).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                  -{" "}
                  {new Date(response?.workingHours?.end).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                  Everyday
                  <span className="text-[#34B2C0] font-medium mx-1">except </span>
                  <span className="text-[#34B2C0] font-semibold ">
                    {response?.weekendDays?.map((w, index) => {
                      if (index + 1 === response?.weekendDays.length) {
                        return w;
                      } else {
                        return w + " , ";
                      }
                    })}
                  </span>
                </div>
              </div>
              <hr />
              <div className="my-1 ">
                <div className=" font-semibold mb-2 text-[18px] ">Amenities</div>
                {response?.amenities.map((a) => (
                  <div key={a.name} className="flex  text-[16px] gap-[10px] my-1 items-center">
                    <img className="h-[25px] w-[25px] object-cover " src={a.icon} alt={a.name} />
                    {a.name}
                  </div>
                ))}
              </div>
              <hr />

              <div className="flex  gap-[20px]">
                <div>
                  <div className="font-semibold mb-1 text-[18px]">check in price :</div>

                  <div className="flex gap-[5px] items-center text-[18px] ">
                    <span className="flex  items-center gap-[5px] font-semibold  ">
                      {response?.checkinPriceCoins}
                      <img className="w-[25px] h-[25px] " src={coin} alt="coins" />
                    </span>
                    <span className="font-semibold"> or {response?.checkinPriceCash} EGP</span>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <span className="font-semibold text-[18px]">Notes</span>
                <br />
                {response?.notes}
              </div>
              <hr />
              <div>
                <span className="font-semibold text-[18px]">NAS</span>
                <br />
                {response?.nas}
              </div>
              <hr />
              <div className=" font-semibold mb-2 text-[18px] ">Metabase Dashboard</div>
              <div className="flex  gap-[40px] text-[18px]">
                <div className="flex flex-col items-center">
                  Revenue :
                  <br />
                  <span className="font-semibold">{response?.metabaseDashboard?.REVENUE}</span>
                </div>

                <div className="flex flex-col items-center">
                  Finance :
                  <br />
                  <span className="font-semibold">{response?.metabaseDashboard?.FINANCE}</span>
                </div>

                <div className="flex flex-col items-center">
                  Community :
                  <br />
                  <span className="font-semibold">{response?.metabaseDashboard?.COMMUNITY}</span>
                </div>

                <div className="flex flex-col items-center">
                  Experience :
                  <br />
                  <span className="font-semibold center">
                    {response?.metabaseDashboard?.EXPERIENCE}
                  </span>
                </div>
              </div>
              <hr />
              {/* <div>
                <span className="font-semibold text-[18px]">Facility List</span>
                <br />
              </div> */}
            </div>
          </div>
        ) : (
          <LoadingCircle />
        )}
      </Layout>
    </>
  );
};

export default Location;
