import React from "react";
import profile from "../../../assets/profile.png";
import timeIcon from "../../../assets/icons/timeIcon.png";
import CheckedInCardModal from "./CheckedInCardModal";
import moment from "moment";

const CheckedInCard = ({ response, status, fetchData }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function diff_hours(dt2, dt1) {
    var diff = (new Date(dt2)?.getTime() - new Date(dt1)?.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.ceil(Math.abs(diff)));
  }

  return (
    <>
      <CheckedInCardModal
        open={open}
        handleClose={handleClose}
        response={response}
        setOpen={setOpen}
        fetchData={fetchData}
      />
      <div
        onClick={handleClickOpen}
        className=" cursor-pointer bg-white border-[1px] border-[#EFEFEF] rounded-[10px] w-[290px]  min-h-[250px] p-[15px] shadow-lg"
      >
        <div className="flex justify-between">
          <div className="w-1/2">
            <img
              className="w-[60px] h-[69px] rounded-[10px]  object-cover"
              src={
                response?.user?.profilePicture
                  ? response?.user?.profilePicture
                  : profile
              }
              alt="profile"
            />
            <div className="font-bold mt-[8px] text-[18px] flex flex-wrap">{response.name}</div>
          </div>
          <div className="w-1/2">
            {status === "checked-in" ? (
              <span className="text-[#34B2C0] h-[40px] text-[16px] bg-[#34b2c033] font-bold py-[8px] px-[16px] rounded-[23px]">
                Checked in
              </span>
            ) : (
              <span className="text-[#797979] h-[40px] text-[16px] bg-[#79797933] font-bold py-[8px] px-[16px] rounded-[23px]">
                Checked out
              </span>
            )}
          </div>
        </div>

        <div className="mt-[15px]">
          <span className="text-[#797979] text-[18px] ">Checked in at</span>
          <br />
          <span className="font-bold text-[16px]">{response?.date}</span>
        </div>

        <div className="mt-[15px]">
          <span className="text-[#797979] text-[18px] ">Check in Duration</span>
          <br />
          <span className="flex items-center text-[16px]">
            <img
              className=" w-[18px] h-[18px]  object-contain mr-[5px]"
              src={timeIcon}
              alt="timeIcon"
            />
            <span className="font-bold">
              {response?.startDate} -{" "}
              {response?.checkOutTime
                ? moment(response?.checkOutTime).format("LT")
                : "Now"}{" "}
            </span>
          </span>
          <br />
          {response?.checkOutTime &&
            diff_hours(response?.checkOutTime, response?.checkInTime) && (
              <span className="text-[#797979] ">
                {diff_hours(response?.checkOutTime, response?.checkInTime)}{" "}
                hours
              </span>
            )}{" "}
        </div>
      </div>
    </>
  );
};

export default CheckedInCard;
