import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { http } from "../../../utils/httpCommon";
import stopIcon from "../../../assets/stop.svg";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";

const EditPackage = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [packages, setPackages] = useState([
    { name: "", passes: "", price: "", discount: "", expireIn: "" },
  ]);

  const fetchPackage = async () => {
    setLoading(true);
    try {
      const res = await http.get(`/package-tier/${params?.id}`);
      setResponse(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchPackage();
  }, []);

  const navigate = useNavigate();

  const schema = yup.object().shape({
    name: yup.string().required("Package Tier Name is required"),
    packages: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Package Name is required"),
        passes: yup
          .number()
          .typeError("Passes must be a number")
          .required("Passes is required")
          .max(9999, "Passes must be at most 9999"),
        price: yup
          .number()
          .typeError("Price must be a number")
          .required("Price is required")
          .max(999999, "Price must be at most 999999"),
        discount: yup
          .number()
          .typeError("Discount must be a number")
          .required("Discount is required")
          .min(0, "Discount must be at least 0")
          .max(100, "Discount must be at most 100"),
        expireIn: yup
          .number()
          .typeError("Expire In must be a number")
          .required("Expire In is required")
          .oneOf([14, 30, 60, 90, 180], "Invalid value for Expire In"),
      })
    ),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      packages: packages,
    },
  });

  useEffect(() => {
    if (response) {
      const defaultValues = {
        name: response?.packageTier?.name,
        packages: response?.packageTier?.packages?.map((pkg) => ({
          name: pkg.name,
          passes: pkg.passes,
          price: pkg.price,
          discount: pkg.discount,
          expireIn: pkg.expireIn,
        })),
      };

      setPackages(response?.packageTier?.packages);
      reset(defaultValues);
    }
  }, [response]);

  const onSubmit = async (data) => {
    try {
      await http.put(
        `/package-tier/${response?.packageTier?._id}`,
        JSON.stringify({ name: data?.name, packages: data?.packages })
      );
      toast.success("Package updated successfully");
    } catch (error) {
      if (error?.response?.data?.error?.details) {
        error?.response?.data?.error?.details?.map((err) => {
          toast.error(err.message);
        });
      } else {
        toast.error(
          error?.response?.data?.message ? error?.response?.data?.message : "Something went wrong"
        );
      }
    }
  };

  const handleAddPackages = () => {
    setPackages([...packages, { name: "", passes: "", price: "", discount: "", expireIn: "" }]);
  };

  const handleDeletePackage = (index) => {
    const updatedPackages = [...packages];
    updatedPackages.splice(index, 1);
    setPackages(updatedPackages);
  };

  return (
    <Layout>
      {loading ? (
        <LoadingCircle />
      ) : (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="flex flex-col px-4 min-h-500"
        >
          <div className="text-[30px] mb-2" style={{ fontFamily: "gilroy-bold" }}>
            Edit Package Tier
          </div>

          <form className="w-[65%]" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-2">
              <div className="text-[18px] my-[5px]" style={{ fontFamily: "gilroy-bold" }}>
                Package Tier Name
              </div>
              <div>
                <TextInput label="Package Tier Name" {...register("name")} />
                <p className="text-red-500 text-[14px] ">{errors.name?.message}</p>
              </div>
            </div>

            {packages.map((pack, index) => (
              <div key={index}>
                <div
                  className="text-[18px] flex items-center justify-between gap-10 my-3"
                  style={{ fontFamily: "gilroy-bold" }}
                >
                  Package {index + 1}
                  {/* Delete button for additional packages */}
                  {index > 0 && (
                    <div
                      onClick={() => handleDeletePackage(index)}
                      className="text-red-500 cursor-pointer flex items-centergap-"
                    >
                      <img
                        src={stopIcon}
                        className="w-[30px] h-[30px] object-contain cursor-pointer"
                        alt="deleted"
                      />
                      Remove Package
                    </div>
                  )}
                </div>
                <div className="mt-2 flex justify-between w-full gap-4">
                  <div className="w-1/2">
                    <FormControl fullWidth sx={{ border: "none !important" }}>
                      <InputLabel> Select package name</InputLabel>
                      <Controller
                        control={control}
                        name={`packages[${index}].name`}
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Select
                            sx={{
                              color: "black",
                              backgroundColor: "white",
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "#EFEFEF",
                              },
                            }}
                            onBlur={onBlur} // notify when input is touched
                            onChange={onChange} // send value to hook form
                            variant="outlined"
                            labelId="Select package name"
                            id=" Select package name"
                            label=" Select package name"
                            value={value}
                            inputRef={ref}
                            defaultValue=""
                          >
                            <MenuItem value=""> None </MenuItem>
                            <MenuItem value="EXPLORER"> EXPLORER </MenuItem>
                            <MenuItem value="GO-GETTER"> GO-GETTER </MenuItem>
                            <MenuItem value="STRATEGIST"> STRATEGIST </MenuItem>
                            <MenuItem value="HIGH-FLYER"> HIGH-FLYER </MenuItem>
                            <MenuItem value="POWER HOUSE"> POWER HOUSE </MenuItem>
                            <MenuItem value="FANATIC"> FANATIC </MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>

                    <p className="text-red-500 text-[14px] ">
                      {errors.packages && errors.packages[index]?.name?.message}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <div>
                      <TextInput label="Passes" {...register(`packages[${index}].passes`)} />
                      <p className="text-red-500 text-[14px] ">
                        {errors.packages && errors.packages[index]?.passes?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex justify-between w-full gap-4">
                  <div className="w-1/2">
                    <div>
                      <TextInput label="Price" {...register(`packages[${index}].price`)} />
                      <p className="text-red-500 text-[14px] ">
                        {errors.packages && errors.packages[index]?.price?.message}
                      </p>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <div>
                      <TextInput label="Discount" {...register(`packages[${index}].discount`)} />
                      <p className="text-red-500 text-[14px] ">
                        {errors.packages && errors.packages[index]?.discount?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[49%] mt-5">
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel> Expire In</InputLabel>
                    <Controller
                      control={control}
                      name={`packages[${index}].expireIn`}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            backgroundColor: "white",

                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="expireIn"
                          id="expireIn"
                          value={value}
                          label="expireIn"
                          inputRef={ref}
                          defaultValue=""
                        >
                          <MenuItem value=""> None </MenuItem>
                          <MenuItem value={14}> 14 </MenuItem>
                          <MenuItem value={30}> 30 </MenuItem>
                          <MenuItem value={60}> 60 </MenuItem>
                          <MenuItem value={90}> 90 </MenuItem>
                          <MenuItem value={180}> 180 </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>

                  <p className="text-red-500 text-[14px] ">
                    {errors.packages && errors.packages[index]?.expireIn?.message}
                  </p>
                </div>
              </div>
            ))}

            <div
              onClick={handleAddPackages}
              className="flex w-full justify-end text-[16px] mt-2 text-[#34B2C0] cursor-pointer"
              style={{ fontFamily: "gilroy-bold" }}
            >
              + Add more Packages
            </div>

            <div className="mt-4 mb-6 flex justify-end gap-10">
              <Button onClick={() => navigate("/")} secondary={true} invert={true}>
                Cancel
              </Button>
              <Button type="submit" secondary={true}>
                Confirm
              </Button>
            </div>
          </form>
        </motion.div>
      )}
    </Layout>
  );
};

export default EditPackage;
