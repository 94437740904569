import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { useVasCart } from "../../../Context/VasCart";
import EditButton from "../../../assets/EditButton.svg";

const VasCard = ({ data }) => {
  const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } =
    useVasCart();

  const quantity = getItemQuantity(data);

  return (
    <div className="w-[200px] border-[1px] border-[#EFEFEF] bg-[#FFF] py-2 px-3  rounded-[10px]">
      {/* images */}
      <div className="mt-[5px] relative">
        <LazyLoadImage
          className="h-[110px] w-[160px] object-contain rounded-[10px] "
          height={70}
          width={70}
          src={data?.image}
          alt={data?.name}
        />
        <div className="flex justify-end">
          <Link
            className="  absolute top-0 z-20 text-[16px] rounded-[10px] px-2 py-1 font-semibold text-[#34B2C0] bg-[#D6F0F2]"
            to={`/operations/vas/${data?._id}`}
          >
            More info
          </Link>
        </div>
      </div>

      <div className="flex justify-between items-center mt-[20px]">
        <div className="text-[15px]  cursor-pointer  w-fit rounded-[10px] px-2 py-1 font-semibold text-[#797979] bg-[#EFEFEF]">
          {data?.type}
        </div>
        <Link to={`/operations/vas/edit-vas/${data?._id}`}>
          <img src={EditButton} alt="edit" className="h-[24px]" />
        </Link>
      </div>

      <div className="mt-[10px] text-[16px] font-semibold text-[#797979]">{data?.name}</div>
      <div className="text-[#011516] text-[25px] " style={{ fontFamily: "gilroy-bold" }}>
        {data?.price} EGP
      </div>
      <div className="text-[15px]  text-[#797979]">{data?.unit}</div>

      {/* cart items */}
      <div className="mt-auto ">
        {quantity === 0 ? (
          <button
            className="w-full bg-black text-white text-[16px] rounded-[10px] font-bold py-1 mt-2 border-[1px] border-[black]"
            onClick={() => increaseCartQuantity(data)}
          >
            Add to Order
          </button>
        ) : (
          <div className="flex flex-col items-center border-[1px] rounded-[10px] border-[#E4E4E4] p-1 ">
            <div className="flex justify-between w-full items-center gap-2 ">
              <button
                className="bg-black w-[30px] h-[30px] flex justify-center items-center rounded-[10px] text-[white] font-bold"
                onClick={() => decreaseCartQuantity(data)}
              >
                -
              </button>
              <span className="text-[16px] font-bold">{quantity} </span>
              <button
                className="bg-black w-[30px] h-[30px] flex justify-center items-center rounded-[10px] text-[white] font-bold"
                onClick={() => increaseCartQuantity(data)}
              >
                +
              </button>
            </div>

            {/* <button
              onClick={() => removeFromCart(data.id)}
              variant="danger"
              className=""
              size="sm"
            >
              Remove
            </button> */}
            <div></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VasCard;
