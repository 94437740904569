import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { Link } from "react-router-dom";
import Table from "../../../components/DataGrid/Table";
import moment from "moment";
import { debounce } from "lodash";

const Packages = () => {
  const navigator = useNavigate();
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 800);
  };

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const onFilterChange = useCallback(async (filterModel) => {
    let searchVal = filterModel?.items[0]?.value;
    if (searchVal?.length > 0) {
      const res = await http.get(
        `/package-tier?limit=${pageState.pageSize}&page=${pageState.page}&packageTierName=${searchVal}`
      );
      const rowsData = res?.data?.packageTiers?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.count,
      }));
    } else {
      debounced();
    }
  }, []);

  const fetchData = async (searchQuery) => {
    setPageState((old) => ({
      ...old,
      isLoading: true,
    }));
    try {
      const res = await http.get(
        `/package-tier?limit=${pageState.pageSize}&page=${pageState.page}&packageTierName=${searchQuery}`
      );
      const rowsData = res?.data?.packageTiers?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.count,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  const debounced = useCallback(debounce(fetchData, 700), []);

  const columns = [
    {
      field: "name",
      headerName: "Package Tier Name",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center py-2">
            <Link
              to={`/operations/package/${params.row.id}`}
              className=" object-cover h-[66px]  flex justify-center items-center font-semibold text-[16px] hover:text-[#34B2C0] transition-all"
            >
              {params?.row?.name}
            </Link>
          </div>
        );
      },
    },

    {
      field: "numsOfPackage",
      headerName: "Number of packages",
      width: 320,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="  font-medium transition-all text-[16px]   ">
              {params?.row?.packages?.length || 0}
            </span>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Creation Date",
      width: 320,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ">
            <span className="  font-medium transition-all text-[16px]   ">
              {moment(params?.row?.createdAt).format("DD/MM/YYYY") || 0}
            </span>
          </div>
        );
      },
    },
  ];

  const handleActionButton = () => {
    navigator("/operations/add-package");
  };

  return (
    <>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState?.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        fetchData={fetchData}
        onFilterChange={onFilterChange}
        columns={columns}
        title={"Packages"}
        actionButton={"Add Package Tier"}
        handleActionButton={handleActionButton}
      />
    </>
  );
};

export default Packages;
